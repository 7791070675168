<template>
	<transition name="modal">
		<div class="modal modal-mask">
			<div class="modal-container">
				<div
					:class="{'has-title': title}"
					class="modal-top"
				>
					<div
						v-if="title"
						class="modal-title"
					>
						{{ title }}
					</div>
					<button
						type="button"
						class="modal-close"
						@click="onCloseClick"
					>
						<BaseIcon
							name="cancel"
							class="modal-close__icon"
							:class="{'has-title': title}"
						/>
					</button>
				</div>
				<h2
					class="modal-header"
					:class="{'has-title': title}"
				>
					{{ header }}
				</h2>

				<div
					class="modal-body"
					:class="{'has-title': title}"
				>
					<!-- @slot the main text of the body -->
					<slot name="body"></slot>
				</div>

				<div
					v-if="buttonText"
					class="modal-footer"
					:class="{'has-title': title}"
				>
					<RoundedButton
						class="modal-footer__button modal-footer__button-left"
						theme="extra"
						@click="onCloseClick"
					>
						{{ buttonText }}
					</RoundedButton>
				</div>
			</div>
		</div>
	</transition>
</template>

<script lang="ts" setup>
import RoundedButton from '@lmt-rpb/RoundedButton/RoundedButton.vue';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';

interface Props {
	header?: string,
	title?: string,
	buttonText?: string,
}

defineProps<Props>();

const emit = defineEmits(['SmallModal:Close']);

const onCloseClick = () => emit('SmallModal:Close');

</script>

<style lang="scss" scoped>
.modal-mask {
	position: fixed;
	z-index: 9998;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 0 0.8rem;
	background-color: $color-black-t5;
	display: flex;
	transition: opacity 0.3s ease;
}

.modal-container {
	position: relative;
	margin: auto auto;
	max-width: 80rem;

	@media (min-width: $breakpoint-small) {
		max-width: 73.6rem;
	}
	@media (min-width: $breakpoint-large) {
		max-width: 80rem;
	}
	padding:0;
	background-color: $color-small-modal-background;
	&:has(.has-title) {
		background-color: #fff;
		border-radius: 5px;
		max-width: 80rem;
	}
	border-radius: $border-radius-checkout-tiles;
	box-shadow: 0.3rem 0.6rem 1.4rem 0 $color-dark-gray-t2;

	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		/*  IE specific rules */
		height: 40rem;
	}
}
.modal-header,
.modal-body,
.modal-footer{
	margin-left: 7rem;
	margin-right: 7rem;
	&:not(.has-title) {
		margin-left: 1.6rem;
		margin-right: 1.6rem;
		@media (min-width: $breakpoint-small) {
			margin-left: 4rem;
			margin-right: 4rem;
		}
		@media (min-width: $breakpoint-large) {
			margin-left: 10rem;
			margin-right: 10rem;
		}
	}
}

.modal-header {
	font-size: $font-medium-1;
	margin-top: 2.4rem;
	margin-bottom: 0.8rem;

	@media (min-width: $breakpoint-small) {
		margin-top: 4rem;
		font-size: $font-large-1;
	}
	@media (min-width: $breakpoint-large) {
		font-size: $font-large-1;
		margin-top: 4.4rem; // 10rem - 2.4rem margin bottom for title - 2*1.6 padding title
		margin-bottom: 2.4rem;
	}
	font-weight: $font-weight-bold;
	padding: 0;
	text-align: left;
}

.modal-body {
	font-family: $font-family;
	font-size: $font-small-2;
	@media (min-width: $breakpoint-small) {
		font-size: $font-medium-2;
	}
	@media (min-width: $breakpoint-large) {
		font-size: $font-medium-1;
	}
	font-weight: $font-weight-regular;
	line-height: $line-height-text-small;

	&.has-title {
		padding-bottom: 2.4rem;
		font-size: $font-medium-1;
	}
}

.modal-footer {
	display: flex;
	justify-content: center;

	margin-top: 2.4rem;
	margin-bottom: 1.6rem;
	@media (min-width: $breakpoint-small) {
		margin-top: 3.2rem;
		margin-bottom: 3.2rem;
	}
	@media (min-width: $breakpoint-large) {
		margin-top: 5.2rem;
		margin-bottom: 6.2rem;
	}
}

.modal-top {
	padding: 1.6rem;
	margin-bottom: -5rem;
	@media (min-width: $breakpoint-small) {

	}
	@media (min-width: $breakpoint-large) {
		padding: 2.4rem;
		margin-bottom: 3.4rem;
	}
	display: flex;
	justify-content: end;

	&.has-title {
		padding: 1.6rem 2.4rem;
		margin-bottom: 2.4rem;
		background-color: $color-small-modal-title-background;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		box-shadow: 0.3rem 0.6rem 1.4rem 0 $color-dark-gray-t2;
	}

	.modal-title {
		color:white;
		flex-grow: 1;
		font-weight: $font-weight-bold;
		font-size: $font-medium-2;
	}
	.modal-close {
		border: none;
		background: none;
		color: $color-primary;
		display: inline-flex;
		align-items: center;

		&:focus,
		&:active {
			outline: none;
		}

		&__icon {
			width: $font-small-1;
			height: $font-small-1;
			@media (min-width: $breakpoint-small) {
				width: $font-medium-2;
				height: $font-medium-2;
			}

			:deep(path) {
				fill: $color-small-modal-close-button;
			}

			&.has-title {
				width: $font-small-1;
				height: $font-small-1;
				:deep(path) {
					fill: white;
				}
			}


		}
	}
}
:deep(button.button) {
	border-radius: $small-modal-button-radius;
	background: $color-small-modal-button !important;
}

.modal .modal-footer__button {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: $font-small-2;
	padding: 0.8rem 2.4rem;
	@media (min-width: $breakpoint-large) {
		padding: 1.6rem 4rem;
		font-size: $font-small-1;
	}

	&-right {
		margin-left: 1rem;
	}

	&--icon {
		width: $font-small-2;
		height: $font-small-2;
		fill: currentcolor;
		margin-right: 0.7rem;
	}
}

</style>
