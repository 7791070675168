<template>
	<FilterType
		title="Kundenbewertungen"
		class="customer-rating-filter"
	>
		<ShowMoreContainer :item-count="listOfAvailableFilters.length">
			<template #visibleContent>
				<template
					v-for="(availableFilter, index) in visibleFilters"
					:key="index"
				>
					<li class="customer-rating-filter__list-item">
						<Checkbox
							v-model="ratingFilters"
							:label="availableFilter.label"
							:custom-value="availableFilter.value"
						/>
					</li>
				</template>
			</template>

			<template #hiddenContent>
				<template
					v-for="(availableFilter, index) in hiddenFilters"
					:key="index"
				>
					<li class="customer-rating-filter__list-item">
						<Checkbox
							v-model="ratingFilters"
							:label="availableFilter.label"
							:custom-value="availableFilter.value"
						/>
					</li>
				</template>
			</template>
		</ShowMoreContainer>
	</FilterType>
</template>

<script lang="ts" setup>
import FilterType from '@lmt-rpb/FilterType/FilterType.vue';
import ShowMoreContainer from '@lmt-rpb/ShowMoreContainer/ShowMoreContainer.vue';
import Checkbox from '@lmt-rpb/CheckBox/CheckBox.vue';
import { computed, ref } from 'vue';
import { additionalCheckboxFilterTypes } from '@/js/data/index';
import { useStore } from '@/components/common/store';

const store = useStore();

const listOfAvailableFilters = ref(additionalCheckboxFilterTypes);
const visibleFilters = listOfAvailableFilters.value.slice(0, 5);
const hiddenFilters = listOfAvailableFilters.value.slice(5);

const ratingFilters = computed({
	get() {
		return store.state.searchMask.additionalCheckboxFilters?.ratingAttributes as string[];
	},
	set(value: string[]) {
		store.commit(
			'searchMask/updateFormData',
			{ additionalCheckboxFilters: { ratingAttributes: value } }
		);
	}
});
</script>

<style lang="scss" scoped>
.customer-rating-filter {
	margin: 0;
	padding: 0;
	list-style: none;

	&__list-item {
		display: block;

		:deep(.checkbox .checkbox__label) {
			font-weight: $font-weight-regular;
			font-size: $font-small-1;
		}

		&:not(:last-child) {
			margin-bottom: 1.6rem;
		}
	}
}
</style>
