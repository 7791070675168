<template>
	<div class="checkout-info-button">
		<slot
			name="button"
		>
			<button
				type="button"
				:disabled="disabled"
				class="checkout-info-button__button"
				@click="showModal"
			>
				<p class="checkout-info-button__button-text">
					{{ buttonText }}
				</p>
				<BaseIcon
					v-if="!disabled"
					class="checkout-info-button__button-arrow"
					name="arrowRight"
				/>
			</button>
		</slot>
		<OfferDetailsModal
			ref="modal"
			class="offer-details-modal-button__modal"
			:title="modalTitle"
			@OfferDetailsModal:closeModal="closeModal"
		>
			<slot
				v-if="modalOpen"
			></slot>
		</OfferDetailsModal>
	</div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import OfferDetailsModal from '@lmt-rpb/OfferDetailsModal/OfferDetailsModal.vue';

type Props = {
	buttonText: string,
	modalTitle: string
	disabled?: boolean
}

withDefaults(defineProps<Props>(), { disabled: false });

const modal = ref<InstanceType<typeof OfferDetailsModal>>();

const modalOpen = ref(false);

const closeModal = () => {
	modalOpen.value = false;
};

const showModal = (event: Event): void => {
	// necessary for carousel to correctly init image sizes
	// carousel doesn't calculate the width if they are not rendered
	modalOpen.value = true;
	event?.preventDefault();
	modal.value?.open();
};

defineExpose({
});
</script>

<style lang="scss" scoped>
.checkout-info-button {
	&__button {
		appearance: none;
		background: none;
		border: none;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		font-weight: $font-weight-semibold;
		color: $color-link;
		font-size: $font-small-1;
		margin-top: 0.8rem;
		column-gap: 1.6rem;
		text-decoration: underline $color-link;

		&:disabled {
			color: $color-inactive;
			text-decoration: underline $color-inactive;
			cursor: default;
		}
	}

	&__button-text {
		margin: 0;
		flex-grow: 1;
		flex-shrink: 0;
	}

	&__button-arrow {
		fill: currentcolor;
		width: $font-small-2;
		height: $font-small-2;
	}
}
</style>
