const roomTypes = [
	{ id: 1, label: 'Einzelzimmer', value: 'SingleRoom' },
	{ id: 2, label: 'Doppelzimmer', value: 'DoubleRoom' },
	{ id: 3, label: 'Familienzimmer', value: 'FamilyRoom' },
	{ id: 4, label: 'Bungalow', value: 'Bungalow' },
	{ id: 5, label: 'Appartement', value: 'Apartment' },
	{ id: 6, label: 'Studio', value: 'Studio' },
	{ id: 7, label: 'Suite', value: 'Suite' },
	{ id: 8, label: 'Villa', value: 'Villa' },
	{ id: 9, label: 'Superior', value: 'SuperiorRoom' },
	{ id: 10, label: 'Dreibettzimmer', value: 'ThreeBedRoom' },
	{ id: 11, label: 'Mehrbettzimmer', value: 'MultiBedRoom' },
	{ id: 12, label: 'Ferienwohnung', value: 'HolidayFlat' },
	{ id: 13, label: 'Ferienhaus', value: 'SummerCottage' },
	{ id: 14, label: 'Deluxe-Zimmer', value: 'DeluxeRoom' },
	{ id: 15, label: 'Duplexzimmer', value: 'DuplexRoom' },
] as const;

// A type alias exists in interfaces/common with the same name
// TODO: it would make sense to export these types from here and base them on the main roomTypes array to avoid typos and later inconsistencies between types if they change

export type RoomType = typeof roomTypes[number];
export default roomTypes;
