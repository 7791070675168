<template>
	<dialog
		ref="modalRef"
		class="search-modal"
	>
		<div
			class="search-modal__content"
			:class="{ 'search-modal__content--searchable' : searchable }"
		>
			<div
				class="search-modal__header"
				@click="handleClose"
			>
				<span class="search-modal__header--title">{{ header }}</span>
				<base-icon
					name="close"
					class="search-modal__header--close"
				/>
			</div>
			<div
				v-if="searchable"
				class="search-modal__filter-input-container"
			>
				<input
					v-model="filterExpression"
					class="search-modal__filter-input"
					type="text"
					placeholder="Suchen"
					autofocus
				/>
				<BaseIcon
					class="search-modal__filter-input-icon"
					name="search"
				></BaseIcon>
				<BaseIcon
					v-if="inputClearable && filterExpression?.length"
					class="search-modal__filter-input-clear-icon"
					name="clearSearch"
					@click="clearInput"
				></BaseIcon>
			</div>
			<div class="search-modal__main">
				<slot></slot>
			</div>
			<div
				class="search-modal__footer"
			>
				<DropdownClearButton
					v-if="withClearButton"
					class="search-modal__clear-button"
					@click="emit('SearchModal:clear')"
				/>
				<div
					v-if="showButtonRow"
					class="search-modal__button-row"
				>
					<slot
						name="footer"
						v-bind="{ handleClose, handleApply }"
					>
						<button
							v-if="cancelButton"
							class="search-modal__button search-modal__button--cancel"
							type="button"
							@click="handleClose"
						>
							{{ cancelButton }}
						</button>
						<button
							class="search-modal__button search-modal__button--apply"
							type="button"
							@click="handleApply"
						>
							{{ applyButton }}
						</button>
					</slot>
				</div>
			</div>
		</div>
	</dialog>
</template>

<script setup lang="ts">
import {
	ref, onMounted, computed,
} from 'vue';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import Modal from '@/js/modules/modal';
import DropdownClearButton from '@lmt-rpb/DropdownClearButton/DropdownClearButton.vue';

const modalRef = ref<HTMLDialogElement | null>(null);
let modalInstance: Modal | null = null;

type Props = {
	header: string
	applyButton?: string
	cancelButton?: string
	searchable?: boolean
	withClearButton?: boolean
	inputClearable?: boolean
}

const props = defineProps<Props>();

const emit = defineEmits([
	'SearchModal:close',
	'SearchModal:applyChanges',
	'SearchModal:cancel',
	'SearchModal:clear',
	'SearchModal:clearInput',
]);
const filterExpression = defineModel<string>();

onMounted(() => {
	modalInstance = new Modal(modalRef.value!, window.matchMedia('(min-width: 1300px)'), () => { emit('SearchModal:close'); });
	if (window.innerWidth > 1300) {
		modalInstance?.closeModal();
	}
});

const showButtonRow = computed(() => props.applyButton || props.cancelButton);

const clearInput = () => {
	if (!props.inputClearable) return;
	filterExpression.value = '';
};

const openModal = () => {
	if (window.innerWidth <= 1300) {
		modalInstance?.openModal();
		filterExpression.value = '';
	}
};

const handleApply = (e) => {
	modalInstance?.closeModal();
	emit('SearchModal:applyChanges');
};

const handleClose = () => {
	modalInstance?.closeModal();
	emit('SearchModal:close');
};

defineExpose({ openModal, handleClose });
</script>

<style lang="scss" scoped>
.search-modal {
	height: 100dvh;
	max-height: 100%;
	border: none;
	background-color: $color-white;
	padding: 0;
	min-width: 100%;

	&__content {
		display: grid;
		grid-template-rows: auto 1fr auto;
		height: 100dvh;

		&--searchable {
			grid-template-rows: auto auto 1fr auto;
		}
	}

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: $color-link;
		color: $color-white;
		font-size: $font-small-1;
		box-shadow: 0 0.4rem 0.6rem 0 #00000029;
		padding: 0.8rem 1.6rem;
		outline: none;
	}

	&__header--title {
		font-weight: $font-weight-semibold;
		font-size: $font-small-1;
	}

	&__header--close {
		padding: 0;
		height: 1.4rem;
		width: 1.4rem;
		color: $color-white;
		border: none;
		cursor: pointer;
		fill: $color-white;
	}

	&__filter-input-container {
		position: relative;
		height: 4rem;
	}

	&__filter-input-icon {
		position: absolute;
		top: 0.8rem;
		left: 1.6rem;
		width: 2.4rem;
		height: 2.4rem;
		fill: $color-primary;
	}

	&__filter-input-clear-icon {
		position: absolute;
		top: 1.2rem;
		right: 1.6rem;
		cursor: pointer;
	}

	&__filter-input {
		appearance: none;
		border: none;
		border-bottom: solid 0.1rem $color-border;
		padding: 0.8rem 3.2rem 0.8rem 4.8rem;
		font-size: $font-small-1;
		width: 100%;

		&:focus {
			outline: none;
		}
	}

	&__main {
		overflow: hidden auto;
		padding: 0.8rem 0.8rem 3.2rem;
		
		&:focus-visible {
			outline: none;
		}
	}

	&__footer {
		width: 100%;
	}

	&__button-row {
		display: flex;
		justify-content: center;
		align-items: center;
		column-gap: 0.8rem;
		background-color: $color-light-gray;
		padding: 1.6rem 0;
	}

	&__button {
		padding: 0.8rem 2.4rem;
		border: none;
		border-radius: $border-radius-x-large;
		font-weight: $font-weight-semibold;
		font-size: $font-small-1;
		color: $color-white;
		cursor: pointer;

		&--cancel,
		&--apply {
			height: 4rem;
			min-width: 13.5rem;
		}

		&--cancel {
			background-color: $color-white;
			border: $color-primary 1px solid;
			color: $color-primary;

			&:hover {
				transition: all 0.2s;
				background-color: $color-primary;
				color: $color-white;
			}

			&:focus-visible {
				outline: none;
			}
		}

		&--apply {
			background-color: $color-extra;

			&:hover {
				transition: all 0.2s;
				background-color: $color-extra-t8;
			}
		}
	}
}

@media (min-width: $breakpoint-small) {
	.search-modal {
		&__header {
			padding: 1.6rem 2.4rem;
		}

		&__header--title {
			font-size: $font-medium-2;
		}

		&__header--close {
			height: 1.6rem;
			width: 1.6rem;
		}

		&__footer {
			gap: 7.2rem;
		}

		&__button {
			padding: 0.8rem 4rem;

			&--cancel {
				min-width: 18.3rem;
			}

			&--apply {
				min-width: 19.4rem;
			}
		}
	}
}
</style>
