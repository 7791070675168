/* eslint-disable object-curly-newline */
export default [
	{ id: 1, showInMenu: false, value: 'SelfCatering', label: 'Nur Übernachtung' },
	{ id: 1, showInMenu: false, value: 'OnlyStay', label: 'Nur Übernachtung' },
	{ id: 1, showInMenu: true, value: 'NoBoard', label: 'Nur Übernachtung' },
	{ id: 2, showInMenu: true, value: 'Breakfast', label: 'Frühstück' },
	{ id: 2, showInMenu: false, value: 'BreakfastEconomy', label: 'Frühstück' },
	{ id: 2, showInMenu: false, value: 'BreakfastSuperior', label: 'Frühstück' },
	{ id: 3, showInMenu: true, value: 'HalfBoard', label: 'Halbpension' },
	{ id: 3, showInMenu: false, value: 'HalfBoardPlus', label: 'Halbpension Plus' },
	{ id: 3, showInMenu: false, value: 'HalfBoardEconomy', label: 'Halbpension' },
	{ id: 3, showInMenu: false, value: 'HalfBoardSuperior', label: 'Halbpension Plus' },
	{ id: 4, showInMenu: true, value: 'FullBoard', label: 'Vollpension' },
	{ id: 4, showInMenu: false, value: 'FullBoardPlus', label: 'Vollpension Plus' },
	{ id: 4, showInMenu: false, value: 'FullBoardEconomy', label: 'Vollpension' },
	{ id: 4, showInMenu: false, value: 'FullBoardSuperior', label: 'Vollpension Plus' },
	{ id: 5, showInMenu: true, value: 'AllInclusive', label: 'All Inclusive' },
	{ id: 5, showInMenu: false, value: 'AllInclusivePlus', label: 'All Inclusive Plus' },
	{ id: 5, showInMenu: false, value: 'AllInclusiveEconomy', label: 'All Inclusive' },
	{ id: 5, showInMenu: false, value: 'AllInclusiveSuperior', label: 'All Inclusive Plus' },
	{ id: 99, showInMenu: false, value: 'AccordingDescription', label: 'Verpflegung lt. Beschreibung' },
	{ id: 99, showInMenu: false, value: 'SpecialBoard', label: 'Verpflegung lt. Beschreibung' },
	{ id: 99, showInMenu: false, value: 'Unknown', label: 'Unknown' },
] as const;

// defined as object for easier access
export const Board = {
	SelfCatering: 'Nur Übernachtung',
	OnlyStay: 'Nur Übernachtung',
	NoBoard: 'Nur Übernachtung',
	Breakfast: 'Frühstück',
	BreakfastEconomy: 'Frühstück',
	BreakfastSuperior: 'Frühstück',
	HalfBoard: 'Halbpension',
	HalfBoardPlus: 'Halbpension Plus',
	HalfBoardEconomy: 'Halbpension',
	HalfBoardSuperior: 'Halbpension Plus',
	FullBoard: 'Vollpension',
	FullBoardPlus: 'Vollpension Plus',
	FullBoardEconomy: 'Vollpension',
	FullBoardSuperior: 'Vollpension Plus',
	AllInclusive: 'All Inclusive',
	AllInclusivePlus: 'All Inclusive Plus',
	AllInclusiveEconomy: 'All Inclusive',
	AllInclusiveSuperior: 'All Inclusive Plus',
	AccordingDescription: 'Verpflegung laut Beschreibung',
	SpecialBoard: 'Verpflegung laut Beschreibung',
	Unknown: 'Unknown',
};
