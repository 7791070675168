<template>
	<label
		class="pill"
		:class="{'is-selected': isSelected, 'is-focued': isFocused}"
	>
		<span class="pill__label">{{ item.label }}</span>
		<input
			type="checkbox"
			class="pill__input"
			@change="onChange"
			@focus="isFocused = true"
			@blur="isFocused = false"
		/>
	</label>
</template>

<script lang="ts" setup>
import {
	computed,
	ref,
} from 'vue';

type PillSelectType = {
	label: string;
	value: string;
}
interface Props {
	modelValue?: string | string[],
	item?: PillSelectType,
	single?: boolean,
}

const props = withDefaults(defineProps<Props>(), {
	modelValue: '',
	item: () => ({ label: '', value: '' }),
	single: false,
});

const emit = defineEmits(['update:modelValue']);

const isFocused = ref(false);

const model = computed({
	get() {
		return props.modelValue || [];
	},
	set(newValue) {
		emit('update:modelValue', newValue);
	}
});

const isSelected = computed((): boolean => {
	if (props.single) {
		return model.value === props.item.value;
	}
	return model.value.includes(props.item.value);
});

const onChange = (): void => {
	if (props.modelValue === props.item.value) { return; }

	if (props.single) {
		model.value = (!isSelected.value && props.item.value) || '';
		return;
	}

	if (isSelected.value) {
		model.value = (model.value as string[]).filter((value: string | number) => value !== props.item.value);
	} else {
		(model.value as string[]).push(props.item.value);
	}
};
defineExpose({
	onChange,
	model,
	isSelected
});

</script>

<style lang="scss" scoped>
.pill {
	@include media-query-up ($breakpoint-mobilelarge) {
		font-size: $font-small-2;
	}

	& {
		display: inline-block;
		position: relative;
		padding: 0.85rem 2rem;
		border: 2px solid $color-primary;
		border-radius: $border-radius-x-large;
		color: $color-primary;
		font-size: $font-small-3;
		text-align: center;
		cursor: pointer;
	}

	&:hover,
	&:focus {
		background: $color-primary-l6;
	}

	&.is-focused {
		background: $color-primary-l6;
	}

	&.is-selected {
		background: $color-primary;
		color: $color-white;

		&:hover,
		&:focus,
		&.is-focused {
			border-color: $color-primary-l0;
			background: $color-primary-l0;
		}
	}

	.pill__label {
		@include media-query-up ($breakpoint-mobilelarge) {
			font-size: $font-small-1;
		}

		& {
			font-size: $font-small-2;
			font-weight: $font-weight-semibold;
		}
	}

	.pill__input {
		@include sr-only;
	}
}

</style>
