<template>
	<div class="flex-filter">
		<div class="flex-filter__header">
			<h2 class="flex-filter__title">
				{{ flexItem.Title }}
			</h2>
		</div>
		<div class="flex-filter__container">
			<Checkbox
				v-model="flexFilters"
				class="flex-filter__checkbox"
				label="Flexible Stornierung & Umbuchung"
			>
				<span class="flex-filter__tooltip"><span v-html="flexItem.Name"></span>
					<InfoTooltip
						placement="bottom"
						modifier-class="info-tooltip__body"
						icon-name="infoFillNew"
						@click.prevent
					>
						{{ flexItem.Tooltip }}
					</InfoTooltip>
				</span>
			</Checkbox>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import Checkbox from '@lmt-rpb/CheckBox/CheckBox.vue';
import InfoTooltip from '@lmt-rpb/InfoTooltip/InfoTooltip.vue';
import { useStore } from '@/components/common/store';

const store = useStore();

const flexItem = {
	Title: 'Flexibel buchen',
	Name: 'Flexible Stornierung & <br>Umbuchung',
	Tooltip: 'Angebote, die günstig oder kostenlos stornierbar und umbuchbar sind oder bei denen ein solcher flexibler Tarif zubuchbar ist.'
};

const flexFilters = computed({
	get() {
		return store.getters['searchMask/flex'];
	},
	set(value: boolean) {
		store.commit('searchMask/updateFlex', value);
	},
});

</script>

<style lang="scss" scoped>
.flex-filter {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1.6rem;

	:deep(.filter-radio-button__input) {
		margin: 0;
		padding: 0;
	}

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		column-gap: 0.8rem;
		cursor: default;
		width: 100%;
	}

	&__title {
		padding: 0;
		text-align: left;
		font-family: $font-family;
		color: $color-filter-type-header-text;
		font-size: $font-medium-3;
		font-weight: $font-weight-semibold;
		margin-bottom: 0;
	}

	&__button {
		background-color: transparent;
		border: 0 solid;
		color: $color-primary;
		font-size: $font-small-3;
		font-weight: $font-weight-semibold;
		text-align: left;
		margin: 0;
		cursor: pointer;
	}

	&__container {
		display: block;
		width: 100%;
		align-items: center;
		padding-inline: 0;
		padding-right: 3rem;
		padding-left: 0.8rem;

		& > * {
			max-width: 28rem;
		}

		:deep(.filter-radio-button > .filter-radio-button__input) {
			flex-shrink: 0;
			align-self: flex-start;
		}
	}

	&__checkbox {
		width: 100%;
	}

	&__label {
		width: 100%;
	}

	&__tooltip {

		:deep(.info-tooltip__body) {
			font-size: $font-small-1;
			left: -2rem !important;
			right: 35% !important;
			max-width: 31rem !important;
			width: 100%;
		}

		:deep(.info-tooltip__arrow) {
			left: 2rem !important;
		}

		:deep(.info-tooltip__icon) {
			overflow: visible;
			opacity: 1;
			width: 1.6rem;
			height: 1.6rem;
			fill: $color-primary;
			cursor: pointer;
		}

		:deep(.info-tooltip) {
			margin-left: 1rem;
			vertical-align: middle;

			svg path {
				fill: $color-primary;
			}
		}

		:deep(.info-tooltip__icon-container) {
			height: fit-content;
			width: fit-content;
		}
	}
	:deep(.checkbox) {

		.checkbox__label {
			width: 100%;
		}

		.checkbox__label::before {
			top: 34%;
		}

		.checkbox__label svg.checkbox__icon {
			top: 34%;
		}
	}
}

@media (min-width: $breakpoint-verysmall) {
	.flex-filter {
		&__container {
			& > * {
				max-width: 100%;
			}
		}
	}
}

@media (min-width: $breakpoint-extralarge) {
	.flex-filter {
		&__header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			background-color: initial;
			box-shadow: none;
		}

		&__title {
			font-size: $font-medium-2;
		}
	}
}
</style>
