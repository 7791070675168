<template>
	<div>
		<RowList class="travel-duration-content">
			<template #content>
				<div
					class="travel-duration-content__header"
				>
					<ul class="travel-duration-content__body">
						<PillSelect
							v-for="tag in travelDurationOptions"
							:key="tag.label"
							v-model="pillModel"
							:item="tag"
							:single="true"
							class="travel-duration-content__pill"
						/>
					</ul>
				</div>
				<div class="travel-duration-content__box">
					<span class="travel-duration-content__value">genau</span>
					<div
						class="travel-duration-content__items"
					>
						<InnerDropdown
							ref="innerDropdown"
							:placeholder="''"
							:show-footer="false"
							:model-value="dropdownModel"
							:show-toggle-icon="true"
							@DropdownField:OutsideClick="clickOutside"
						>
							<ul class="travel-duration-content__list">
								<li
									v-for="(day, index) in 28"
									:key="`${day}-${index}`"
									role="button"
									class="travel-duration__content"
									:class="{'travel-duration__content--active' : activeElement === index }"
									@click="selectDropdownDay(day.toString())"
								>
									{{ day }}
								</li>
							</ul>
						</InnerDropdown>
					</div>
					<span class="travel-duration-content__value">Tage</span>
				</div>
			</template>
		</RowList>
		<p
			v-if="model.duration[0] > offerDurationDiff || errorMessage"
			class="travel-duration-content__warning"
		>
			{{
				model.duration[0] > offerDurationDiff ?
					'Die gewählte Dauer ist zu lang für den eingestellten Zeitraum. Möchten Sie die Reisedaten ändern?' :
					errorMessage
			}}
		</p>
	</div>
</template>

<script lang="ts" setup>
import {
	computed, ref,
} from 'vue';
import PillSelect from '@lmt-rpb/PillSelect/PillSelect.vue';
import RowList from '@lmt-rpb/RowList/RowList.vue';
import InnerDropdown from '@lmt-rpb/InnerDropdown/InnerDropdown.vue';
import { TRAVEL_DURATION_EXACT } from '@global-js/constants';
import { useStore } from '@/components/common/store';
import { travelDurationOptions, travelDurationSingleValues } from './travelDurationOptions';

const store = useStore();

interface Props {
	modelValue: {exact: boolean, duration: number[]},
	offerDurationDiff: number,
	errorMessage?: string,
}
const props = withDefaults(defineProps<Props>(), { errorMessage: '' });

const emit = defineEmits(['update:modelValue', 'TravelDurationContent:IsExactSelected', 'TravelDurationContent:outOfRange', 'TravelDurationContent:TagSelected', 'TravelDurationContent:PillChanged']);

const innerDropdown = ref<InstanceType<typeof InnerDropdown>>();

const activeElement = ref<null | number>(null);

const isShowDropdown = ref(
	!props.modelValue.exact
	&& props.modelValue.duration.length === 1
	&& !travelDurationSingleValues.find((o) => o === props.modelValue.duration[0])
);

const model = computed({
	get() {
		return props.modelValue;
	},
	set(newValue) {
		emit('update:modelValue', newValue);
	}
});

const pillModel = computed({
	get() {
		const { exact, duration } = model.value;
		let result = 'exact';
		if (!exact && duration) {
			result = duration.join(',');
		}
		return result;
	},
	set(newValue) {
		isShowDropdown.value = false;
		model.value = {
			exact: !newValue || newValue === TRAVEL_DURATION_EXACT,
			duration: !newValue || newValue === TRAVEL_DURATION_EXACT ? [props.offerDurationDiff] : newValue.split(',').map(Number),
		};
		emit('TravelDurationContent:PillChanged', newValue, [props.offerDurationDiff]);
	}
});

const dropdownModel = computed({
	get() {
		const { exact, duration } = model.value;
		let result = '';
		if ((isShowDropdown.value || !travelDurationSingleValues.includes(duration[0])) && !exact && duration.length === 1) {
			result = duration[0].toString();
		}
		return result;
	},
	set(newValue) {
		isShowDropdown.value = true;
		model.value = {
			exact: false,
			duration: [Number(newValue)]
		};
	}
});

const clickOutside = () => {
	if (innerDropdown.value) {
		innerDropdown.value.open = false;
	}
};

function selectDropdownDay(day: string) {
	dropdownModel.value = day.toString();
	innerDropdown.value?.closeDropdown();
	emit('TravelDurationContent:PillChanged', false);
}

defineExpose({
	model
});

</script>

<style lang="scss" scoped>
:deep(.dropdown.is-open .dropdown__box) {
	overflow: initial !important;
}

:deep(.pill__label) {
	margin: 0 1rem;
}

:deep(.inner-dropdown) {
	margin: 0 auto;
}

:deep(.inner-form-field) {
	height: 4.2rem;
}

:deep(.form-field) {
	width: 6.9rem;
	height: 4.2rem;
}

:deep(.pill) {
	padding: 0.85rem 1rem;
	margin: 0;
}

@media (min-width: $breakpoint-extralarge) {
	:deep(.pill__label) {
		margin: 0;
		padding: 0.55rem 1rem;
	}
}

:deep(.form-field__chevron-icon) {
	width: 1.1rem !important;
	display: block !important;
}

.travel-duration-content {
	&__body {
		display: flex;
		flex-direction: column;
		width: 100%;
		padding: 0;
		margin: 0;
		max-width: 40rem;

		@media (min-width: $breakpoint-extralarge) {
			display: flex;
			width: auto;
			flex-wrap: wrap;
			padding: 0;
			justify-content: center;
			flex-direction: inherit;
		}
	}

	&__list {
		padding: 0;
		margin: 0;
	}

	&__box {
		display: flex;

		@include max-width(41rem);

		@media (min-width: $breakpoint-extralarge) {
			justify-content: center;
		}
	}

	&__items {
		margin: 1rem 0;
	}

	&__warning {
		display: none;
		text-align: center;
		color: $color-warning;
		font-size: $font-small-2;
		margin: 1rem 4.5rem;

		@media (min-width: $breakpoint-large) {
			display: inline-block;
		}
	}

	.travel-duration-content__header {
		@include max-width(45rem);

		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		padding: 0 2.1rem 0 1.1rem;
		border: none;

		@media (min-width: $breakpoint-extralarge) {
			border-bottom: 0.1rem solid $color-primary-l4;
			padding: 2rem 2.1rem 2rem 1.1rem;
		}
	}

	&__label {
		font-size: $font-small-1;
		font-weight: $font-weight-semibold;
		color: $color-primary;

		&--active {
			color: $color-white;
		}
	}

	.travel-duration-content__pill {
		width: inherit;
		padding: 1rem;
		display: flex;
		border-radius: 0;
		border: none;
		border-bottom: 0.1rem solid $color-primary-l4;

		@media (min-width: $breakpoint-extralarge) {
			border-radius: 2.5rem;
			border: 2px solid $color-primary;
			margin: 0 0.6rem 1.4rem;
			padding: 0;
		}
	}

	.travel-duration-content__value {
		margin: 0 1.7rem;
		color: $color-primary;
		font-size: $font-small-1;
		font-weight: $font-weight-semibold;
		text-align: center;
		align-items: center;
		display: flex;
	}

	.travel-duration-content__warning {
		display: inline-block;
		margin-top: 2rem;
		position: relative;
		margin-left: 3rem;

		@media (min-width: $breakpoint-extralarge) {
			display: none;
		}
	}

	.travel-duration-content__warning-icon {
		width: 2rem;
		height: 2rem;
		fill: $color-primary;
		vertical-align: middle;
		position: absolute;
		left: -2.5rem;
	}

	.travel-duration-content__selection {
		padding: 2rem 2.5rem;
		max-width: 50rem;
		margin: 0 auto;

		&.is-inactive {
			.travel-duration-content__value {
				color: $color-state-disabled;
			}
		}
	}

	.travel-duration__box {
		padding: 0;
		margin: 0;
	}

	.travel-duration__content {
		display: block;
		width: 100%;
		padding: 1rem 0;
		border: none;
		background: none;
		color: $color-text-l24;
		font-size: $font-small-1;
		font-weight: $font-weight-semibold;
		text-decoration: none;
		text-align: center;

		&--active{
			background: $color-primary-l1;
			color: $dropdown-active-color;
		}

		&:hover {
			background: $color-primary-l6;
			color: $color-text-l24;
		}
	}
}
</style>
