<template>
	<div class="sea-view-filter">
		<h3 class="sea-view-filter__header">
			Aussicht
		</h3>
		<div class="sea-view-filter__input-container">
			<Checkbox
				v-model="seaViewFilter"
				class="sea-view-filter__checkbox"
				label="Zimmer mit Meerblick"
			/>
		</div>
	</div>
</template>

<script lang="ts" setup>
import Checkbox from '@lmt-rpb/CheckBox/CheckBox.vue';
import { computed } from 'vue';
import { useStore } from '@/components/common/store';

const store = useStore();

// We can get/set the state like this because it's a checkbox field and
// only 'FullOcean' is possible at the moment
const seaViewFilter = computed({
	get() {
		return store.state.searchMask.roomViews.length > 0;
	},
	set(value: boolean) {
		store.commit('searchMask/updateFormData', {
			roomViews: value ? ['FullOcean', 'PartialOcean', 'PartialOrFullOcean'] : [],
		});
	},
});
</script>

<style lang="scss" scoped>
.sea-view-filter {
	&__header {
		margin: 0;
		padding: 0;
		cursor: default;
		text-align: left;
		color: $color-filter-type-header-text;
		font-size: $font-medium-3;
		font-weight: $font-weight-semibold;
		line-height: 2.451rem;
		margin-bottom: 2.65rem;
	}

	&__input-container {
		position: relative;
		display: flex;
		padding: 0 0.8rem;

		:deep(.checkbox .checkbox__label) {
			font-weight: $font-weight-regular;
			font-size: $font-small-1;
		}
	}

	&__checkbox {
		box-shadow: none;
	}
}

// Media Queries
@media (min-width: $breakpoint-small) {
	.sea-view-filter__input-container {
		padding: 0.8rem;
		margin-bottom: 0;
	}
}

@media (min-width: $breakpoint-extralarge) {
	.sea-view-filter__header {
		padding: 0;
		margin-bottom: 1.9rem;
		font-size: $font-medium-2;
		font-weight: $font-weight-semibold;
		line-height: 2.724rem;
	}

	.sea-view-filter__input-container {
		padding: 0;
		padding-left: 0.8rem;
	}
}
</style>
