<template>
	<div
		class="dropdown"
		:class="dropdownClasses"
		:aria-expanded="`${open}`"
		@click="onFieldClick"
		@keydown.enter.prevent="onEnter"
	>
		<FormField
			ref="field"
			v-model:selected="model"
			tabindex="0"
			class="dropdown__field"
			:class="formFieldClass"
			:label="label"
			:readonly="readonly"
			:board="board"
			:icon="icon"
			:open="open"
			:custom-clear-icon="customClearIcon"
			:placeholder="placeholder"
			:allow-clear="allowClear"
			:show-toggle-icon="showToggleIcon"
			:prevent-mousedown="preventMousedown"
			:disabled="maxTravelDurationError"
			@FormField:Focus="$emit('DropdownField:Focus', $event)"
			@FormField:Blur="$emit('DropdownField:Blur', $event)"
			@FormField:Clear="$emit('DropdownField:Clear', $event)"
			@input-focus="$emit('DropdownField:InputFocus', $event)"
			@toggle="toggleDropdown"
		/>
		<div
			ref="box"
			class="dropdown__box"
			:class="{'input-focused': isFocused}"
		>
			<div class="dropdown__inner">
				<button
					v-if="showFooter && showCloseIcon"
					type="button"
					class="dropdown__close"
					@click="$emit('DropdownField:Cancel')"
				>
					<svg
						class="dropdown__close-icon"
						viewBox="0 0 27 32"
					>
						<title>{{ getLocaleString('close') }}</title>
						<path d="M15.25 16.188l11 10.938c0.625 0.625 0.625 1.563 0 2.188-0.563 0.625-1.563 0.625-2.188 0l-10.688-10.75-10.75 10.75c-0.625 0.625-1.625 0.625-2.188 0-0.625-0.625-0.625-1.563 0-2.188l11-10.938v-0.375l-11-10.938c-0.625-0.625-0.625-1.563 0-2.188 0.563-0.625 1.563-0.625 2.188 0l10.75 10.75 10.688-10.75c0.625-0.625 1.625-0.625 2.188 0 0.625 0.625 0.625 1.563 0 2.188l-11 10.938v0.375z" />
					</svg>
				</button>

				<div class="dropdown__container">
					<slot v-if="contentRender"></slot>
				</div>
				<DropdownClearButton
					v-if="withClearButton"
					@click.stop="$emit('DropdownField:Clear')"
					@keydown.enter.stop="$emit('DropdownField:Clear')"
				/>
				<div
					v-if="showFooter"
					class="dropdown__footer"
				>
					<rounded-button
						v-if="withCancelButton"
						ref="ok"
						aria-label="Verwerfen"
						class="dropdown__cancel"
						@click.stop="$emit('DropdownField:Clear')"
						@keydown.enter.stop="$emit('DropdownField:Clear')"
					>
						<span class="dropdown__cancel">{{ buttonCancel }}</span>
					</rounded-button>
					<rounded-button
						ref="ok"
						aria-label="Auswahl wählen"
						:disabled="maxTravelDurationError || buttonDisabled"
						@click.stop="$emit('DropdownField:Ok')"
						@keydown.enter.stop="$emit('DropdownField:Ok')"
					>
						<span class="dropdown__ok">{{ buttonWording }}</span>
					</rounded-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>

import FormField from '@lmt-rpb/FormField/FormField.vue';
import DropdownClearButton from '@lmt-rpb/DropdownClearButton/DropdownClearButton.vue';
import RoundedButton from '@lmt-rpb/RoundedButton/RoundedButton.vue';
import { getLocaleString } from '@utils/environmentUtils';
import { EventBus } from '@global-js/event-bus';
import {
	computed, getCurrentInstance, onBeforeMount, onBeforeUnmount, onMounted, ref, watch,
} from 'vue';
import { useStore } from '@/components/common/store';

const store = useStore();

interface Props {
	modelValue?: string,
	label?: string,
	icon?: string,
	readonly?: boolean,
	wide?: boolean,
	manual?: boolean,
	closeOnEsc?: boolean,
	showFooter?: boolean,
	formFieldClass?: string,
	board?: boolean,
	placeholder?: string,
	allowClear?: boolean,
	showToggleIcon?: boolean,
	showCloseIcon?: boolean,
	disabled?: boolean,
	buttonDisabled?: boolean,
	preventMousedown?: boolean,
	buttonWording?: string,
	buttonCancel?: string,
	withCancelButton?: boolean,
	withClearButton?: boolean,
	maxTravelDurationError?: boolean,
	isFocused?: boolean,
	customClearIcon?: string,
	searchable?: boolean,
}

const props = withDefaults(defineProps<Props>(), {
	modelValue: '',
	label: '',
	icon: '',
	readonly: true,
	wide: false,
	manual: undefined,
	closeOnEsc: true,
	showFooter: true,
	formFieldClass: '',
	board: false,
	placeholder: 'Beliebig',
	allowClear: false,
	showToggleIcon: true,
	showCloseIcon: false,
	disabled: false,
	preventMousedown: true,
	buttonWording: 'Übernehmen',
	buttonCancel: 'Verwerfen',
	withCancelButton: false,
	withClearButton: false,
	maxTravelDurationError: false,
	isFocused: false,
	customClearIcon: undefined
});

const emit = defineEmits([
	'update:modelValue',
	'DropdownField:Navigate',
	'DropdownField:FieldClick',
	'DropdownField:OutsideClick',
	'DropdownField:Toggle',
	'DropdownField:Focus',
	'DropdownField:Cancel',
	'DropdownField:Ok',
	'DropdownField:InputFocus',
	'DropdownField:Blur',
	'DropdownField:Clear',
	'DropdownField:open',
]);

const model = computed({
	get() {
		return props.modelValue;
	},
	set(newValue: string) {
		if (typeof newValue === 'string') {
			emit('update:modelValue', newValue);
		}
	}
});

const root = ref();

const el = ref<HTMLElement | null>(null);

const open = ref<boolean>(props.manual || false);

const contentRender = ref<boolean>(props.manual || false);

const box = ref<HTMLElement | null>(null);

const field = ref<InstanceType<typeof FormField> | null>(null);

const dropdownClasses = computed((): string[] => {
	const state = open.value ? 'is-open' : '';
	const theme = props.wide ? 'wide' : 'narrow';
	const footer = !props.showFooter ? 'is-without-footer' : '';

	return [state, `dropdown--${theme}`, footer];
});

const isHeaderSearch = computed((): boolean => root.value?.type.name === 'SearchForm-Header' || root.value?.type.name === 'Solr-Search');

const pageType = computed((): string => store.state.config.pageType);

const calendarDateChanged = computed((): boolean => store.state.calendarDateChanged);

const onKeydown = (e: KeyboardEvent): void => {
	if (open.value && [38, 40].indexOf(e.which) !== -1 && !props.maxTravelDurationError) {
		e.preventDefault();
		emit('DropdownField:Navigate', e.which === 38 ? -1 : 1);
	}
};

const checkTarget = (e: MouseEvent) => {
	if ((e.target as Element).parentElement && ((e.target as Element).parentElement as Element).classList.contains('destination-field')) {
		return false;
	}

	if (open.value && e.target && !el.value?.contains((e.target as Node))) {
		emit('DropdownField:OutsideClick');
	}

	if (open.value && props.searchable) {
		const inputEl = el.value?.querySelector('input');
		if (inputEl) {
			inputEl.focus();
		}
	}
};

const onOpen = (parent: string) => {
	if (parent === 'autocomplete') {
		document.addEventListener('click', checkTarget);
	}
};

const openDropdown = (): void => {
	if (props.disabled || props.maxTravelDurationError) {
		return;
	}
	open.value = true;
	emit('DropdownField:open');
};

const closeDropdown = (): void => {
	open.value = false;
};

const toggleDropdownEsc = (): void => {
	emit('DropdownField:OutsideClick');
};

const onFieldClick = (e: MouseEvent): void => {
	if (!isHeaderSearch.value && pageType.value === 'themePage' && window.innerWidth > 1440 && !props.maxTravelDurationError) {
		EventBus.$emit('OfferSearch:ScrollUpSearchForm');
	}

	if (!e || !e.target) {
		openDropdown();
		return;
	}

	const target = e.target as Node;
	const clickOnContainer = box.value && box.value.contains(target);

	const clickOnField = el.value?.contains(target) && !clickOnContainer;

	if (props.manual !== undefined && !calendarDateChanged.value) {
		emit('DropdownField:FieldClick', clickOnField, clickOnContainer);
		return;
	}

	// If calendar is open and date changes, prevent toggle dropdown on field click
	if (calendarDateChanged.value) {
		if ((e.target as Element).classList.contains('offer-duration__field')) {
			emit('DropdownField:OutsideClick');
		}
		return;
	}

	if (clickOnField && !open.value && !props.maxTravelDurationError) {
		openDropdown();
	} else if (clickOnField && open.value && !props.maxTravelDurationError) {
		if (props.searchable) {
			return;
		}
		closeDropdown();
	}
};

const toggleDropdown = (e?: MouseEvent): void => {
	if (!open.value && e) {
		onFieldClick(e);
	} else {
		open.value = !open.value;
	}
};

const onEnter = (): void => {
	if (props.manual === undefined) {
		toggleDropdown();
	}
};

watch(() => props.manual, () => {
	open.value = props.manual;
});

watch(() => open.value, () => {
	const inputEl = el.value?.querySelector('input');

	// remove listener if any
	document.removeEventListener('click', checkTarget);
	EventBus.$off('keydown.escape', toggleDropdownEsc);

	if (open.value) {
		if (inputEl) {
			inputEl.focus();
		}

		document.addEventListener('click', checkTarget);

		if (props.closeOnEsc) {
			EventBus.$once('keydown.escape', toggleDropdownEsc); // Why does this only work with eventBus?
		}
	}
	emit('DropdownField:Toggle', open.value);
});

onBeforeMount(() => {
	watch(() => open.value, () => {
		const unwatch = () => {
			contentRender.value = true;
		};
		unwatch();
	});
});

onMounted(() => {
	document.addEventListener('keydown', onKeydown);
	root.value = getCurrentInstance()?.root;
	el.value = getCurrentInstance()?.proxy.$el as HTMLElement;
});

onBeforeUnmount(() => {
	document.removeEventListener('keydown', onKeydown);
});

defineExpose({
	closeDropdown,
	toggleDropdown,
	onOpen,
	open,
	contentRender,
	field
});

</script>

<style lang="scss" scoped>
$dropdown-box-z-index: 9999;
$dropdown-box-z-index: 10;
$dropdown-overlay-z-index: calc(#{$dropdown-box-z-index} + 1);

.dropdown__cancel {
	margin-right: 1rem;
}

.dropdown {
	position: relative;
	outline: none;

	.dropdown__field {
		transition: border-radius 0.1s ease-out 0.35s;

		&::after {
			content: '';
			position: absolute;
			z-index: $dropdown-overlay-z-index;
			top: 100%;
			left: 0;
			width: 100%;
			height: 0;
			margin-top: -1rem;
			transition: height 0.1s ease-out 0.25s;
			border-width: 0 0.1rem;
			border-style: solid;
			border-color: $color-primary-l4;
			background: $color-white;
		}
	}

	.dropdown__box {
		display: flex;
		position: absolute;
		z-index: $dropdown-box-z-index;
		top: 100%;
		left: 50%;
		flex-direction: row;
		width: 100%;
		max-height: 0;
		margin-top: calc(1rem - 0.1rem);
		overflow: hidden;
		transform: translateX(-50%);
		transition: max-height ease-out 0.25s;
		border-bottom-right-radius: 1rem;
		border-bottom-left-radius: 1rem;
		background: $color-white;
	}

	&.is-open .dropdown__box {
		max-height: 55rem;
	}

	.dropdown__inner {
		display: flex;
		flex-direction: column;
		width: 100%;
		overflow: hidden;
		border: 0.1rem solid $color-primary-l4;
		border-bottom-right-radius: 1rem;
		border-bottom-left-radius: 1rem;
	}

	.dropdown__container {
		display: flex;
		flex-direction: column;
		height: calc(100% - 7rem); // 7rem footer (without 2.5rem from close button which is deactivated)
		transition: visibility 0s 0.05s;
	}

	.dropdown__footer {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 7rem;
		padding-right: 1rem;
		padding-left: 1rem;
		border-bottom-right-radius: 1rem;
		border-bottom-left-radius: 1rem;
		background: $color-light-gray;
	}

	.dropdown__close {
		align-self: flex-end;
		margin-top: 1rem;
		margin-right: 0.5rem;
		border: none;
		outline: none;
		background: none;
		fill: $color-primary-l1;
		cursor: pointer;

		&:hover,
		&:active {
			fill: $color-primary-l4;
		}
	}

	.dropdown__close-icon {
		width: 2rem;
		height: 2rem;
		fill: inherit;
	}

	&.is-without-footer {
		.dropdown__container {
			height: 100%;
		}
	}

	&.is-open {
		.dropdown__field {
			transition-delay: 0s;
			border-bottom-right-radius: 0;
			border-bottom-left-radius: 0;

			&::after {
				height: 2rem;
				transition-delay: 0s;
			}
		}

		.dropdown__box {
			transition-delay: 0.1s;
		}

		.dropdown__container {
			visibility: visible;
			transition-delay: 0s;
		}
	}

	&.dropdown--wide {
		.dropdown__inner {
			border-radius: $border-radius-small;
		}

		.dropdown__box {
			width: 200%;
			max-width: 38rem;
			border-top-left-radius: $border-radius-small;
			border-top-right-radius: $border-radius-small;
		}
	}

	@include media-query-up ($breakpoint-extralarge) {
		.dropdown__box {
			left: 50%;
			box-shadow: 0.2rem 0 0.8rem $color-black-t1;
		}

		.dropdown__inner {
			margin-top: 0;
		}

		.dropdown__container {
			visibility: hidden;
		}

		.dropdown__ok {
			display: inline-block;
		}
	}
}
</style>
