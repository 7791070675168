import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "filter-type" }
const _hoisted_2 = { class: "filter-type__title" }
const _hoisted_3 = {
  key: 0,
  class: "filter-type__body--desktop"
}

import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import TransitionExpand from '@lmt-rpb/TransitionExpand/TransitionExpand.vue';
import { ref, computed } from 'vue';
import { useStore } from '@/components/common/store';

interface Props {
	icon?: string
	title?: string
	initialOpen?: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FilterType',
  props: {
    icon: { default: undefined },
    title: { default: 'Initial title' },
    initialOpen: { type: Boolean, default: true }
  },
  emits: ['FilterType:MobileHeaderOpened'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const store = useStore();
const isDesktop = computed(() => store.state.config.isDesktop);

const dropdownOpen = ref(props.initialOpen);
const toggleDropdown = function() {
	if (isDesktop.value) return;
	dropdownOpen.value = !dropdownOpen.value;
	if (dropdownOpen.value) {
		emit('FilterType:MobileHeaderOpened');
	}
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["filter-type__header", { desktop: isDesktop.value }]),
      onClick: toggleDropdown
    }, [
      (_ctx.icon)
        ? (_openBlock(), _createBlock(BaseIcon, {
            key: 0,
            name: _ctx.icon,
            class: "filter-type__title-icon"
          }, null, 8 /* PROPS */, ["name"]))
        : _createCommentVNode("v-if", true),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.title), 1 /* TEXT */),
      (!isDesktop.value)
        ? (_openBlock(), _createBlock(BaseIcon, {
            key: 1,
            name: "chevron",
            class: _normalizeClass(["filter-type__chevron", { toggled: dropdownOpen.value }])
          }, null, 8 /* PROPS */, ["class"]))
        : _createCommentVNode("v-if", true)
    ], 2 /* CLASS */),
    (isDesktop.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "default")
        ]))
      : (_openBlock(), _createBlock(TransitionExpand, {
          key: 1,
          class: "filter-type__body--mobile",
          "show-content": dropdownOpen.value
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default")
          ]),
          _: 3 /* FORWARDED */
        }, 8 /* PROPS */, ["show-content"]))
  ]))
}
}

})