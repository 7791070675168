import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sea-view-filter" }
const _hoisted_2 = { class: "sea-view-filter__input-container" }

import Checkbox from '@lmt-rpb/CheckBox/CheckBox.vue';
import { computed } from 'vue';
import { useStore } from '@/components/common/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'SeaViewFilter',
  setup(__props) {

const store = useStore();

// We can get/set the state like this because it's a checkbox field and
// only 'FullOcean' is possible at the moment
const seaViewFilter = computed({
	get() {
		return store.state.searchMask.roomViews.length > 0;
	},
	set(value: boolean) {
		store.commit('searchMask/updateFormData', {
			roomViews: value ? ['FullOcean', 'PartialOcean', 'PartialOrFullOcean'] : [],
		});
	},
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("h3", { class: "sea-view-filter__header" }, " Aussicht ", -1 /* HOISTED */)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(Checkbox, {
        modelValue: seaViewFilter.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((seaViewFilter).value = $event)),
        class: "sea-view-filter__checkbox",
        label: "Zimmer mit Meerblick"
      }, null, 8 /* PROPS */, ["modelValue"])
    ])
  ]))
}
}

})