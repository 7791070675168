<template>
	<FilterType
		icon=""
		title="Hotelkategorie"
		class="hotel-category-filter"
	>
		<FilterRadioButtonList
			id="hotel-category"
			v-model="selectedCategory"
			:items="hotelCategoryOptions"
			class="hotel-category-filter__list"
		/>
	</FilterType>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import FilterType from '@lmt-rpb/FilterType/FilterType.vue';
import FilterRadioButtonList from '@lmt-rpb/FilterRadioButtonList/FilterRadioButtonList.vue';
import { hotelCategory, hotelCategoryCom } from '@/js/data/hotel-category-types';
import { useStore } from '@/components/common/store';

const props = defineProps({
	showSuns: {
		type: Boolean,
		default: undefined,
	}
});

// if showSuns is provided it should get hotelCategoryCom
const hotelCategoryOptions = computed(() => (props.showSuns ? hotelCategoryCom : hotelCategory));

const store = useStore();

const selectedCategory = computed({
	get: () => store.state.searchMask.hotelCategory?.toString(),
	set: (newValue) => store.commit('searchMask/updateFormData', { hotelCategory: newValue }),
});
</script>

<style lang="scss" scoped>
.hotel-category-filter {
	&__list {
		margin-left: 0.8rem;
	}
}
</style>
