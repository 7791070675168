import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "price-filter" }
const _hoisted_2 = { class: "price-filter__header" }
const _hoisted_3 = { class: "price-filter__value" }

import RangeSlider from '@lmt-rpb/RangeSlider/RangeSlider.vue';
import { computed, ref, watch } from 'vue';
import { useStore } from '@/components/common/store';
import { Currency, formatPrice } from '@/js/utils/priceUtils';

interface Props {
	maxPrice?: number,
	defaultValue?: number,
	currency?: Currency,
	step?: number,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'PriceFilter',
  props: {
    maxPrice: { default: 1600 },
    defaultValue: { default: -1 },
    currency: { default: 'EUR' },
    step: { default: 100 }
  },
  setup(__props: any) {

const props = __props;

const store = useStore();

const price = ref(0);

const dragging = ref(false);

price.value = props.defaultValue !== -1 ? props.defaultValue : props.maxPrice;

watch(() => props.defaultValue, (value) => {
	price.value = value;
});

const roundPrice = (amount: number) => {
	if (amount <= props.step) {
		return props.step;
	}
	return Math.round(amount / props.step) * props.step;
};

const priceValue = computed((): string => {
	if (price.value > (props.maxPrice - props.step)) {
		return 'Beliebig';
	}
	if (price.value < props.step) {
		return `bis ${formatPrice(props.step, props.currency)}`;
	}
	return ` bis ${formatPrice(roundPrice(price.value), props.currency)}`;
});

const onSelect = (count: number) => {
	store.commit('searchMask/updateFormData', { maxPrice: roundPrice(count) });
};

const onDragStart = () => {
	dragging.value = true;
};

const onDragEnd = () => {
	dragging.value = false;
	onSelect(price.value);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("h2", { class: "price-filter__title" }, [
        _createTextVNode(" Reisepreis "),
        _createElementVNode("span", { class: "price-filter__inline-text" }, "pro Person")
      ], -1 /* HOISTED */)),
      _createElementVNode("p", _hoisted_3, _toDisplayString(priceValue.value), 1 /* TEXT */)
    ]),
    _createElementVNode("div", null, [
      _createVNode(RangeSlider, {
        modelValue: price.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((price).value = $event)),
        max: _ctx.maxPrice,
        class: "price-filter__slider",
        "onRangeSlider:Select": onSelect,
        "onRangeSlider:DragStart": onDragStart,
        "onRangeSlider:DragEnd": onDragEnd
      }, null, 8 /* PROPS */, ["modelValue", "max"])
    ])
  ]))
}
}

})