<template>
	<dialog
		ref="offerDetailsModalRef"
		:class="{
			'offer-details-modal': true,
			'offer-details-modal--border': hasSwipeBack(),
		}"
	>
		<div class="offer-details-modal__header">
			<button
				type="button"
				class="offer-details-modal__close offer-details-modal__close--left"
				@click="closeModal()"
			>
				<BaseIcon
					name="chevron"
					class="offer-details-modal__close-icon"
				/>
				<span class="offer-details-modal__close-text">zurück</span>
			</button>
			<h2 class="offer-details-modal__title">
				{{ title }}
			</h2>
			<button
				type="button"
				class="offer-details-modal__close offer-details-modal__close--right"
				@click="closeModal()"
			>
				<span class="offer-details-modal__close-text">Schließen</span>
				<BaseIcon
					name="close"
					class="offer-details-modal__close-icon"
				/>
			</button>
		</div>
		<div class="offer-details-modal__content">
			<slot />
			<button
				v-if="!isDesktop"
				type="button"
				class="offer-details-modal__close-button--flights-tablet"
				@click.prevent="closeModal()"
			>
				<BaseIcon
					name="chevron"
					class="offer-details-modal__close-button-icon"
				/>
				<span class="offer-details-modal__close-button-label">zurück</span>
			</button>
		</div>
		<button
			type="button"
			class="offer-details-modal__close-button"
			@click.prevent="closeModal()"
		>
			<BaseIcon
				name="chevron"
				class="offer-details-modal__close-button-icon"
			/>
			<span class="offer-details-modal__close-button-label">zurück</span>
		</button>
	</dialog>
</template>

<script lang="ts" setup>
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import {
	computed, onMounted, onUnmounted, ref, watch,
} from 'vue';
import { useStore } from '@/components/common/store';

const store = useStore();

const isDesktop = computed((): boolean => store.state.config.isDesktop);

withDefaults(defineProps<{
	title: string,
}>(), {
	title: 'Title'
});

const emit = defineEmits(['OfferDetailsModal:closeModal']);

const offerDetailsModalRef = ref<HTMLDialogElement>();

const open = (): void => {
	if (offerDetailsModalRef.value) {
		offerDetailsModalRef.value.showModal();
		window.history.pushState({ offerdetailsModal: true }, '');
		document.body.style.overflow = 'hidden';
	}
};

const modalExistsAndIsOpen = (): boolean | undefined => offerDetailsModalRef.value && offerDetailsModalRef.value.open;

const closeModalWithoutAnimation = (): void => {
	if (modalExistsAndIsOpen()) {
		offerDetailsModalRef.value?.close();
	}
	emit('OfferDetailsModal:closeModal');
	resetOverflow();
};

const historyBack = (): void => {
	if (window.history.state && window.history.state.offerdetailsModal) {
		window.history.back();
	}
};

const closeDesktopModal = (): void => {
	historyBack();
	offerDetailsModalRef.value?.close();
};

const closeCallback = (): void => {
	if (modalExistsAndIsOpen()) {
		offerDetailsModalRef.value?.close();
		offerDetailsModalRef.value?.classList.remove('close');
		offerDetailsModalRef.value?.removeEventListener('animationend', closeCallback);
		historyBack();
	}
};

const closeModal = (): void => {
	if (isDesktop.value && modalExistsAndIsOpen()) {
		closeDesktopModal();
	} else {
		offerDetailsModalRef.value?.classList.add('close');
		offerDetailsModalRef.value?.addEventListener('animationend', closeCallback);
	}
	resetOverflow();
};

const hasSwipeBack = (): boolean => {
	const ua = navigator.userAgent;
	const ipad = /Macintosh/i.test(ua) && !!navigator.maxTouchPoints && navigator.maxTouchPoints > 1;
	const android = /android/i.test(ua);
	const chrome = /(Chrome)/i.test(ua);
	const ios = /iPad|iPhone|iPod|CriOS/.test(ua) || ipad;

	return (android && chrome) || ios;
};

// Close modal if switching from isDesktop to !isDesktop and vice versa
// to prevent dissapearing modal and hidden body overflow (no scrolling possible)
watch(() => isDesktop.value, () => {
	if (!isDesktop.value && modalExistsAndIsOpen()) {
		closeDesktopModal();
	}
});

const resetOverflow = () => { document.body.style.overflow = 'auto'; };

const handleLinkClick = (event: MouseEvent) => {
	event.preventDefault();
	const href = (event.target as HTMLAnchorElement).getAttribute('href') || (event.target as HTMLAnchorElement).parentElement?.getAttribute('href');
	if (href) {
		const hash = href.slice(1);
		if (hash) {
			const targetElement = offerDetailsModalRef.value?.querySelector(`a[name="${hash}"]`);
			if (targetElement) {
				targetElement.scrollIntoView({ behavior: 'smooth' });
			}
		}
	}
};

const anchorLinks = ref<NodeListOf<HTMLElement> | undefined>();

const setupAnchorLinks = () => {
	anchorLinks.value = offerDetailsModalRef.value?.querySelectorAll<HTMLElement>('a[href^="#"]'); // select links only in modal an not global
	anchorLinks.value?.forEach((anchor) => {
		anchor.addEventListener('click', handleLinkClick);
	});
};

const destroyAnchorLinks = () => {
	anchorLinks.value?.forEach((anchor) => {
		anchor.removeEventListener('click', handleLinkClick);
	});
};

const handleModalMousedown = (e: MouseEvent) => {
  const isInGallery = (e.target as HTMLElement).closest('.gallery-modal');
  const rect = (offerDetailsModalRef.value as HTMLDialogElement).getBoundingClientRect();
  const isInDialog = (
    rect.top <= e.clientY && e.clientY <= rect.top + rect.height &&
    rect.left <= e.clientX && e.clientX <= rect.left + rect.width
  );
  if (!isInDialog && !isInGallery) {
    closeModal();
  }
};

const handleEscapeKeydown = (event: KeyboardEvent) => {
  if (event.key === 'Escape') {
    event.preventDefault();
    const isNotCombinedKey = !(event.ctrlKey || event.altKey || event.shiftKey);
    if (isNotCombinedKey) {
      closeModal();
    }
  }
};

onMounted((): void => {
	setupAnchorLinks();
	window.addEventListener('popstate', closeModalWithoutAnimation);
	// Close on outside click
	if (offerDetailsModalRef.value) {
		offerDetailsModalRef.value.addEventListener('cancel', resetOverflow);
		offerDetailsModalRef.value.addEventListener('mousedown', handleModalMousedown);
	}

	// Close on ESC to also clear history state
	offerDetailsModalRef.value?.addEventListener('keydown', handleEscapeKeydown);
});

onUnmounted(() => {
	offerDetailsModalRef.value?.removeEventListener('cancel', resetOverflow);
	offerDetailsModalRef.value?.removeEventListener('mousedown', handleModalMousedown);
	offerDetailsModalRef.value?.removeEventListener('keydown', handleEscapeKeydown);
	window.removeEventListener('popstate', closeModalWithoutAnimation);
	destroyAnchorLinks();
});

defineExpose({
	open,
});
</script>

<style lang="scss">
/* GLOBAL POLYFILL FOR DIALOG STYLING */
dialog + .backdrop {
	position: fixed;
	inset: 0;
	/* stylelint-disable-next-line color-function-notation */
	background: rgba(0, 0, 0, 10%);
}
</style>

<style lang="scss" scoped>
/* stylelint-disable value-no-vendor-prefix */

$header-height-mobile: 4.34rem;
$header-height-desktop: 5rem;

body.gallery-open .offer-details-modal {
	width: 100%;
}

// POLYFILL FOR DIALOG ELEMENT START
dialog {
	position: fixed;
	inset: 0;
	width: -moz-fit-content;
	width: -webkit-fit-content;
	width: fit-content;
	height: -moz-fit-content;
	height: -webkit-fit-content;
	height: fit-content;
	margin: auto;
	border: solid;
	padding: 1em;
	background: $color-page-background;
	color: $color-black;
	display: block;
	overflow: hidden;
}

dialog:not([open]) {
	display: none;
}

// POLYFILL FOR DIALOG ELEMENT END

@media (min-width: $breakpoint-mobile) {
	dialog {
		top: -1rem;
	}
}

@media (min-width: $breakpoint-large) {
	dialog {
		top: 0;
	}
}

@media (max-width: $breakpoint-extralarge - 1px) {
	dialog[open] {
		animation: show 0.4s ease normal;
	}

	dialog.close {
		animation: hide 0.2s ease normal;
	}

	@keyframes show {
		from {
			transform: translateX(110%);
		}

		to {
			transform: translateX(0%);
		}
	}

	@keyframes hide {
		to {
			transform: translateX(110%);
		}
	}
}

.offer-details-modal {
	padding: 0;
	margin: 0;
	border: none;
	max-width: none;
	max-height: none;
	height: 100%;
	width: 100%;

	@include customScrollbar($color-primary);

	&--border {
		border-left: 0.4rem solid $color-primary-l4;

		&::before {
			height: 5.6rem;
			width: 1.2rem;
			border-radius: $border-radius-x-small;
			background-color: $color-primary-l4;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			content: '';
			position: fixed;
			top: 50%;
			transform: translateY(-50%);
			z-index: 1;
		}

		&::after {
			height: 3rem;
			width: 0.5rem;
			border-radius: $border-radius-x-small;
			background-color: $color-black;
			content: '';
			position: fixed;
			top: 50%;
			transform: translateY(-50%);
			margin-left: 0.1rem;
			z-index: 1;
		}
	}

	&__header {
		height: $header-height-mobile;
		position: sticky;
		top: 0;
		padding: 1rem 1.5rem 1rem 3rem;
		border-bottom: 0.1rem solid $color-primary-l4;
		background: $color-white;
		z-index: 6;
	}

	&__title {
		padding: 0;
		font-family: $font-family;
		font-size: $font-small-1;
		font-weight: $font-weight-semibold;
		text-align: center;
		margin-left: 5.6rem;
	}

	&__close {
		position: absolute;
		top: 50%;
		height: 2rem;
		left: 1rem;
		transform: translateY(-50%);
		border: none;
		background: none;
		fill: $color-primary;
		cursor: pointer;
		z-index: 1;

		&:focus,
		&:active {
			outline: none;
		}

		&-text {
			color: $color-primary;
			font-weight: $font-weight-bold;
			font-size: $font-small-2;
			margin-left: 0.8rem;
		}

		&-icon {
			width: 1.6rem;
			height: 1.8rem;
			transform: rotateZ(270deg);
			margin-top: 0.1rem;
		}

		&--left {
			display: flex;
		}

		&--right {
			display: none;
		}
	}

	&__content {
		overflow-y: scroll;
		height: calc(100% - $header-height-mobile);
		padding-bottom: 4rem;
	}

	@media screen and (min-width: $breakpoint-small) {
		&__content {
			position: relative;
		}
	}

	&__close-button,
	&__close-button--flights-tablet {
		display: flex;
		align-items: center;
		position: fixed;
		right: 4%;
		bottom: 10%;
		width: 11rem;
		height: 4rem;
		padding: 0 0 0 0.4rem;
		color: $color-primary;
		cursor: pointer;
		border: 0.2rem solid $color-primary-l4;
		border-radius: $border-radius-x-large;
		background: $color-page-background;
		box-shadow: 0 0.3rem 0.6rem $color-box-shadow;

		&-label {
			font-size: $font-small-1;
			font-weight: $font-weight-bold;
			color: $color-checkout-font;
		}

		&-icon {
			height: 1rem;
			fill: $color-primary;
			transform: rotate(270deg);
			margin: 0.5rem;
		}

		&--flights-tablet {
			right: unset;
			bottom: unset;
			display: none;
		}
	}

	@include media-query-up ($breakpoint-mobilelarge) {
		&__title {
			margin-left: 0;
		}
	}

	@include media-query-up ($breakpoint-extralarge) {
		padding-bottom: 0.7rem;
		margin: 0 auto;
		max-width: 76.8rem;

		&__header {
			height: $header-height-desktop;
			padding-top: 1.4rem;
		}

		&__close {
			right: 1rem;
			left: unset;

			&-text {
				margin-right: 1.6rem;
			}

			&-icon {
				width: 1.6rem;
				height: 1.8rem;
				transform: rotateZ(0);
			}

			&--right {
				display: flex;
			}

			&--left {
				display: none;
			}
		}

		&__close-button {
			display: none;
		}

		&__content {
			height: calc(100% - $header-height-mobile);
		}
	}
}
</style>
