<template>
	<div
		ref="root"
		class="header-iconbar__entry-wrapper"
	>
		<button
			class="header-iconbar__entry header-iconbar__entry-favorite"
			@click.prevent="openFavorite"
		>
			<div class="header-iconbar__favorite-icon--close"></div>
			<div class="header-iconbar__favorite-icon">
				<svg
					class="header-iconbar__favorite-icon-heart"
					width="32"
					height="27"
					viewBox="0 0 27 32"
				>
					<path
						fill="#333"
						d="M13.813 29.063l-0.375-0.313-0.438-0.375-0.625-0.438-0.563-0.5-0.5-0.438-0.875-0.75-0.563-0.438-0.688-0.625-0.625-0.563-2.125-2.125-0.625-0.688-1.25-1.25-0.688-0.813-0.563-0.75-0.688-0.75-0.438-0.688-0.563-0.813-0.563-0.875-0.438-0.938-0.313-0.938-0.188-0.938-0.125-0.875v-1.5l0.063-0.75 0.25-1.125 0.25-0.75 0.25-0.563 0.25-0.625 0.438-0.75 0.688-0.875 0.688-0.688 1.125-0.813 0.875-0.438 1-0.313 0.625-0.125h0.438l1.438 0.063 1.375 0.375 0.875 0.5 0.75 0.5 0.625 0.563 0.75 0.75 0.5 0.688 0.25 0.563h0.125l0.125-0.25 0.125-0.313 0.25-0.313 0.375-0.563 0.375-0.438 0.438-0.438 0.563-0.5 0.75-0.5 0.75-0.313 0.875-0.375 1.188-0.188h0.688l1.125 0.125 1.125 0.375 0.813 0.438 1.188 0.813 0.75 0.875 0.813 1.125 0.563 1.125 0.5 1.438 0.25 1.5-0.125 2.063-0.063 0.75-0.313 1-0.25 0.688-0.938 1.688-0.688 1.063-0.813 1.063-0.75 0.875-0.938 1.188-0.813 0.938-0.875 0.875-1.313 1.375-0.75 0.75-1 0.938-1.438 1.375-1.5 1.25z"
					/>
				</svg>
				<svg
					class="header-iconbar__favorite-icon-count"
					:class="{'header-iconbar__favorite-icon-count--visible': hotels.length}"
					width="24"
					height="18"
					viewBox="0 0 30 30"
				>
					<circle
						cx="12"
						cy="12"
						r="11"
					/>
					<text
						x="13"
						y="17"
						text-anchor="middle"
						dominant-baseline="top"
					>{{ hotels.length }}</text>
				</svg>
			</div>
			<div class="header-iconbar__entry-label">
				Merkzettel
			</div>
		</button>
		<HeaderFavoriteDropdown
			ref="headerFavoriteDropdown"
			:show-dropdown="showDropdown"
			:hotels="hotels"
		/>
	</div>
</template>

<script lang="ts" setup>
import { FavoritedHotel } from '@/interfaces/api/v1-hotel-list';
import { getCookieHotels, updateCookie, triggerFavoriteChange } from '@/js/modules/bookmark';
import { EventBus } from '@global-js/event-bus';
import {
	onBeforeMount,
	onBeforeUnmount,
	Ref,
	ref,
} from 'vue';
import BookmarkAnalytics from '@/js/modules/analytics/BookmarkAnalytics';
import * as hotelListService from '@services/hotelListService';
import HeaderFavoriteDropdown from './HeaderFavoriteDropdown.vue';

const headerFavoriteDropdown = ref<InstanceType<typeof HeaderFavoriteDropdown> | null>(null);

const root = ref<InstanceType<typeof HTMLDivElement> | null>(null);

const showDropdown = ref(false);

const hotels:Ref<FavoritedHotel[]> = ref([]);

const updateCookieHotels = (apiHotels: FavoritedHotel[]) => {
	const analytics = BookmarkAnalytics.fromFavoritedHotel(apiHotels);
	analytics.fireViewCart();
	if (apiHotels.length !== hotels.value.length) {
		updateCookie(apiHotels);
		triggerFavoriteChange(); // signalises that cookies changed, which fires updateHotel()
	}
};

const requestAndUpdateHotels = async() => {
	if (hotels.value.length) {
		try {
			const hotelIds = hotels.value.map((h: FavoritedHotel) => Number(h.IffCode));
			updateCookieHotels(await hotelListService.get(hotelIds));
		} catch (e) {
			if (e instanceof Error) {
				console.error(e.message);
			}
		}
	}
};

const closeFavorite = (event: MouseEvent) => {
	const target = event.target as HTMLElement;
	if (!headerFavoriteDropdown.value?.$el.contains(target)) {
		document.body.classList.remove('header-favorite-open');
		document.body.removeEventListener('click', closeFavorite, true);
		showDropdown.value = false;
		if (root.value?.contains(target)) {
			event.stopPropagation();
		}
	}
};

const openFavorite = () => {
	document.body.classList.add('header-favorite-open');
	if (document.body.classList.contains('search-open')) {
		document.body.classList.remove('page-header--hidden'); // Fixes vanishing of headerbar
	}
	showDropdown.value = true;
	requestAndUpdateHotels();
	document.body.addEventListener('click', closeFavorite, true);
};

const updateHotel = () => {
	getCookieHotels().then((hotelsCookie) => { hotels.value = hotelsCookie; });
};

onBeforeMount(() => {
	updateHotel();
	EventBus.$on('bookmark:favoriteChange', updateHotel);
});

onBeforeUnmount(() => {
	EventBus.$off('bookmark:favoriteChange', updateHotel);
});

</script>

<style lang="scss" scoped>
.header-iconbar {
	&__favorite-icon {
		position: relative;
		width: 3.2rem;
	}

	&__favorite-icon--close {
		position: absolute;
		top: 5px;
		left: 4px;

		&::before,
		&::after {
			top: 1.5rem;
			left: 1.9rem;
			background-color: transparent;
			height: 0.25rem;
			width: 2.7rem;
			content: '';
			position: absolute;
			border-radius: 0.3rem;
			transition: background-color 0.25s, transform 0.25s ease-out;
		}
	}

	&__favorite-icon-heart {
		transition: width 0.25s, transform 0.25s ease-out;
	}

	&__entry-favorite {
		border-radius: 0.5rem 0.5rem 0 0;
	}

	&__favorite-icon-count {
		transition: opacity 0.25s;
		position: absolute;
		top: 0.4rem;
		right: -0.9rem;
		opacity: 0;

		circle {
			fill: $heart-circle-color;
			stroke: $heart-text-color;
			stroke-width: 1;
		}

		text {
			fill: $heart-text-color;
			font-size: $font-small-2;
			font-weight: $font-weight-semibold;
		}

		&--visible {
			opacity: 1;
		}
	}
}

.header-favorite-open {
	overflow: hidden;

	.header-iconbar {
		&__entry-favorite {
			background: $header-bar-bg;
		}

		&__favorite-icon--close {
			&::before,
			&::after {
				background: $color-primary;
			}

			&::before {
				transform: rotate(225deg);
			}

			&::after {
				transform: rotate(135deg);
			}
		}

		&__favorite-icon {
			.header-iconbar__favorite-icon-heart {
				transform: rotate(225deg);
				width: 0;
			}
		}

		&__favorite-icon-count {
			opacity: 0;
		}
	}
}

/* Tablet portrait */
@media (min-width: $breakpoint-small) {
	.header-favorite-open {
		overflow: visible;
	}
}

</style>
