import { EventBus } from '@global-js/event-bus';
import Cookies from 'js-cookie';
import { MAX_FAVORITE_HOTELS_LIMIT, MAX_COOKIE_SIZE_LIMIT } from '@global-js/constants';
import { FavoritedHotel } from '@/interfaces/api/v1-hotel-list';
import * as hotelListService from '@/components/common/services/hotelListService';
import { getBaseURLParts } from '../global/helper';
import BookmarkAnalytics from './analytics/BookmarkAnalytics';
// overrides the default encoding implementation
// eslint-disable-next-line no-undef
const TTCookies = Cookies.withConverter({
	read(value) {
		return decodeURI(value);
	},
	write(value) {
		if (typeof value === 'string') {
			return encodeURI(value);
		}
		return encodeURI('');
	}
});

const getTTFavoriteCookieName = () => {
	const baseURLParts = getBaseURLParts();
	const TLDshort = baseURLParts[(baseURLParts.length - 1)].toUpperCase();
	var ttCookieName = 'favorites_RESTPLATZBOERSE-' + TLDshort;

	if (TLDshort === 'COM') {
		ttCookieName = 'favorites_RESTPLATZBOERSE-COM';
	}

	if (TLDshort === 'DE') {
		ttCookieName = 'favorites_TOURISTIKBOERSE-DE';
	}

	if (baseURLParts[1] === 'kuoni') {
		ttCookieName = 'favorites_KUONI';
	}

	if (baseURLParts[1] === 'lastminute') {
		ttCookieName = 'favorites_LASTMINUTE-AT';
	}

	return ttCookieName;
};

const getTTFavoriteCookieDomain = () => {
	const baseURLParts = getBaseURLParts();
	return baseURLParts[(baseURLParts.length - 2)] + '.' + baseURLParts[(baseURLParts.length - 1)];
};

const getCookieHotels = async() => {
	const ttCookieName = getTTFavoriteCookieName();
	const cookieValue = TTCookies.get(ttCookieName);
	let ttCookie: FavoritedHotel[] = [];
	if (typeof cookieValue === 'string') {
		try {
			ttCookie = JSON.parse(cookieValue).hotels || [];
			if (ttCookie.length === 0) {
				// remove this call inclusive all async references after migration.
				// eslint-disable-next-line no-use-before-define
				ttCookie = await migrateCookie(cookieValue);
			}
			// If user has more than the limit, trim to limit keeping newest ones
			if (ttCookie.length > MAX_FAVORITE_HOTELS_LIMIT) {
				ttCookie = ttCookie.slice(0, MAX_FAVORITE_HOTELS_LIMIT);
				// Update cookie with trimmed list
				updateCookie(ttCookie);
				// Emit event to update UI
				EventBus.$emit('bookmark:hotelsUpdated', ttCookie);
			}
		} catch {
			// do nothing
		}
	}
	return ttCookie;
};

const getCookieSize = (hotels: FavoritedHotel[]): number => {
	const cookieContent = '{"count":0,"iff":[],"hotels":' + JSON.stringify(Array.from(hotels)) + '}';
	return encodeURI(cookieContent).length;
};

const updateCookie = (hotels: FavoritedHotel[]) => {
	const ttCookieName = getTTFavoriteCookieName();
	const cookieProps = {
		expires: 180,
		domain: getTTFavoriteCookieDomain(),
		secure: true,
		sameSite: 'strict' as const,
	};

	let workingHotels = [...hotels];

	// First ensure we don't exceed the maximum number of hotels
	if (workingHotels.length > MAX_FAVORITE_HOTELS_LIMIT) {
		workingHotels = workingHotels.slice(0, MAX_FAVORITE_HOTELS_LIMIT);
	}

	// Additional size check as safety measure
	while (getCookieSize(workingHotels) > MAX_COOKIE_SIZE_LIMIT && workingHotels.length > 0) {
		workingHotels.pop();
	}

	TTCookies.set(ttCookieName, '{"count":0,"iff":[],"hotels":' + JSON.stringify(Array.from(workingHotels)) + '}', cookieProps);

	// Emit events in next tick to ensure DOM updates
	setTimeout(() => {
		EventBus.$emit('bookmark:hotelsUpdated', workingHotels);
		// Force a bookmark state refresh for each hotel
		workingHotels.forEach((hotel) => {
			const iffCode = typeof hotel.IffCode === 'string' ? parseInt(hotel.IffCode, 10) : hotel.IffCode;
			EventBus.$emit('bookmark:favoriteChange', iffCode, workingHotels.some((h) => h.IffCode === hotel.IffCode));
		});
	}, 0);
};

/**
 * Triggers a custom event for favorite cookie change.
 *
 * @param {Number} iffCode iffCode of Hotel that changes favorite
 * @param {Boolean} favorite True if hotel is added; false if it is removed.
 */
function triggerFavoriteChange(iffCode = -1, favorite = false) {
	const ev = new CustomEvent('favoriteChange', {
		bubbles: true,
		detail: favorite,
	});

	EventBus.$emit('bookmark:favoriteChange', iffCode, favorite);
	document.dispatchEvent(ev);
}

const getFavoritedHotelFromAnalytics = (bookmarkAnalytics: BookmarkAnalytics, hotelCategory: number): FavoritedHotel => {
	let hotel = null;
	if (bookmarkAnalytics.items && bookmarkAnalytics.items.items[0].item_category2) {
		const { item_id: IffCode, item_name: Name, item_category2: Region } = bookmarkAnalytics.items.items[0];
		hotel = {
			IffCode,
			Name,
			Region,
			Category: hotelCategory
		};
	} else {
		throw new Error('Analytics Object Error: ' + bookmarkAnalytics);
	}
	return hotel;
};

const addEntryToFavoriteCookie = (bookmarkAnalytics: BookmarkAnalytics, hotelCategory: number) => {
	const newHotel = getFavoritedHotelFromAnalytics(bookmarkAnalytics, hotelCategory);
	getCookieHotels().then((cookieHotels) => {
		if (!cookieHotels.find((h) => h.IffCode === newHotel.IffCode)) {
			cookieHotels.unshift(newHotel);
			bookmarkAnalytics.fireAddToCart();
			updateCookie(cookieHotels);
			triggerFavoriteChange(newHotel?.IffCode, true);
			// Emit event with updated hotels list to ensure UI updates
			EventBus.$emit('bookmark:hotelsUpdated', cookieHotels);
		}
	});
};

const removeEntryFromFavoriteCookie = (bookmarkAnalytics: BookmarkAnalytics) => {
	const iff = bookmarkAnalytics.items?.items[0].item_id;
	getCookieHotels().then((cookieHotels) => {
		const removeElementIndex = cookieHotels.findIndex((h) => h.IffCode === iff);
		if (removeElementIndex > -1) {
			bookmarkAnalytics.fireRemoveFromCart();
			cookieHotels.splice(removeElementIndex, 1);
			updateCookie(cookieHotels);
			triggerFavoriteChange(iff, false);
			// Emit event with updated hotels list to ensure UI updates
			EventBus.$emit('bookmark:hotelsUpdated', cookieHotels);
		}
	});
};

const isFavorite = (iffCode: number) => getCookieHotels().then((h) => h.findIndex((c) => c.IffCode === iffCode) > -1);

const migrateCookie = async(cookieValue: string) => {
	let hotels:FavoritedHotel[] = [];
	try {
		const cookieIff = JSON.parse(cookieValue).iff as number[] || [];
		if (cookieIff.length) {
			hotels = await hotelListService.get(cookieIff);
			updateCookie(hotels);
		}
	} catch {
		// do nothing
	}
	return hotels;
};

export {
	addEntryToFavoriteCookie,
	removeEntryFromFavoriteCookie,
	getCookieHotels,
	isFavorite,
	triggerFavoriteChange,
	updateCookie,
};
