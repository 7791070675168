import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "offer-details-modal__header" }
const _hoisted_2 = { class: "offer-details-modal__title" }
const _hoisted_3 = { class: "offer-details-modal__content" }

import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import {
	computed, onMounted, onUnmounted, ref, watch,
} from 'vue';
import { useStore } from '@/components/common/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'OfferDetailsModal',
  props: {
    title: { default: 'Title' }
  },
  emits: ['OfferDetailsModal:closeModal'],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const store = useStore();

const isDesktop = computed((): boolean => store.state.config.isDesktop);



const emit = __emit;

const offerDetailsModalRef = ref<HTMLDialogElement>();

const open = (): void => {
	if (offerDetailsModalRef.value) {
		offerDetailsModalRef.value.showModal();
		window.history.pushState({ offerdetailsModal: true }, '');
		document.body.style.overflow = 'hidden';
	}
};

const modalExistsAndIsOpen = (): boolean | undefined => offerDetailsModalRef.value && offerDetailsModalRef.value.open;

const closeModalWithoutAnimation = (): void => {
	if (modalExistsAndIsOpen()) {
		offerDetailsModalRef.value?.close();
	}
	emit('OfferDetailsModal:closeModal');
	resetOverflow();
};

const historyBack = (): void => {
	if (window.history.state && window.history.state.offerdetailsModal) {
		window.history.back();
	}
};

const closeDesktopModal = (): void => {
	historyBack();
	offerDetailsModalRef.value?.close();
};

const closeCallback = (): void => {
	if (modalExistsAndIsOpen()) {
		offerDetailsModalRef.value?.close();
		offerDetailsModalRef.value?.classList.remove('close');
		offerDetailsModalRef.value?.removeEventListener('animationend', closeCallback);
		historyBack();
	}
};

const closeModal = (): void => {
	if (isDesktop.value && modalExistsAndIsOpen()) {
		closeDesktopModal();
	} else {
		offerDetailsModalRef.value?.classList.add('close');
		offerDetailsModalRef.value?.addEventListener('animationend', closeCallback);
	}
	resetOverflow();
};

const hasSwipeBack = (): boolean => {
	const ua = navigator.userAgent;
	const ipad = /Macintosh/i.test(ua) && !!navigator.maxTouchPoints && navigator.maxTouchPoints > 1;
	const android = /android/i.test(ua);
	const chrome = /(Chrome)/i.test(ua);
	const ios = /iPad|iPhone|iPod|CriOS/.test(ua) || ipad;

	return (android && chrome) || ios;
};

// Close modal if switching from isDesktop to !isDesktop and vice versa
// to prevent dissapearing modal and hidden body overflow (no scrolling possible)
watch(() => isDesktop.value, () => {
	if (!isDesktop.value && modalExistsAndIsOpen()) {
		closeDesktopModal();
	}
});

const resetOverflow = () => { document.body.style.overflow = 'auto'; };

const handleLinkClick = (event: MouseEvent) => {
	event.preventDefault();
	const href = (event.target as HTMLAnchorElement).getAttribute('href') || (event.target as HTMLAnchorElement).parentElement?.getAttribute('href');
	if (href) {
		const hash = href.slice(1);
		if (hash) {
			const targetElement = offerDetailsModalRef.value?.querySelector(`a[name="${hash}"]`);
			if (targetElement) {
				targetElement.scrollIntoView({ behavior: 'smooth' });
			}
		}
	}
};

const anchorLinks = ref<NodeListOf<HTMLElement> | undefined>();

const setupAnchorLinks = () => {
	anchorLinks.value = offerDetailsModalRef.value?.querySelectorAll<HTMLElement>('a[href^="#"]'); // select links only in modal an not global
	anchorLinks.value?.forEach((anchor) => {
		anchor.addEventListener('click', handleLinkClick);
	});
};

const destroyAnchorLinks = () => {
	anchorLinks.value?.forEach((anchor) => {
		anchor.removeEventListener('click', handleLinkClick);
	});
};

const handleModalMousedown = (e: MouseEvent) => {
  const isInGallery = (e.target as HTMLElement).closest('.gallery-modal');
  const rect = (offerDetailsModalRef.value as HTMLDialogElement).getBoundingClientRect();
  const isInDialog = (
    rect.top <= e.clientY && e.clientY <= rect.top + rect.height &&
    rect.left <= e.clientX && e.clientX <= rect.left + rect.width
  );
  if (!isInDialog && !isInGallery) {
    closeModal();
  }
};

const handleEscapeKeydown = (event: KeyboardEvent) => {
  if (event.key === 'Escape') {
    event.preventDefault();
    const isNotCombinedKey = !(event.ctrlKey || event.altKey || event.shiftKey);
    if (isNotCombinedKey) {
      closeModal();
    }
  }
};

onMounted((): void => {
	setupAnchorLinks();
	window.addEventListener('popstate', closeModalWithoutAnimation);
	// Close on outside click
	if (offerDetailsModalRef.value) {
		offerDetailsModalRef.value.addEventListener('cancel', resetOverflow);
		offerDetailsModalRef.value.addEventListener('mousedown', handleModalMousedown);
	}

	// Close on ESC to also clear history state
	offerDetailsModalRef.value?.addEventListener('keydown', handleEscapeKeydown);
});

onUnmounted(() => {
	offerDetailsModalRef.value?.removeEventListener('cancel', resetOverflow);
	offerDetailsModalRef.value?.removeEventListener('mousedown', handleModalMousedown);
	offerDetailsModalRef.value?.removeEventListener('keydown', handleEscapeKeydown);
	window.removeEventListener('popstate', closeModalWithoutAnimation);
	destroyAnchorLinks();
});

__expose({
	open,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("dialog", {
    ref_key: "offerDetailsModalRef",
    ref: offerDetailsModalRef,
    class: _normalizeClass({
			'offer-details-modal': true,
			'offer-details-modal--border': hasSwipeBack(),
		})
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("button", {
        type: "button",
        class: "offer-details-modal__close offer-details-modal__close--left",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (closeModal()))
      }, [
        _createVNode(BaseIcon, {
          name: "chevron",
          class: "offer-details-modal__close-icon"
        }),
        _cache[4] || (_cache[4] = _createElementVNode("span", { class: "offer-details-modal__close-text" }, "zurück", -1 /* HOISTED */))
      ]),
      _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.title), 1 /* TEXT */),
      _createElementVNode("button", {
        type: "button",
        class: "offer-details-modal__close offer-details-modal__close--right",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (closeModal()))
      }, [
        _cache[5] || (_cache[5] = _createElementVNode("span", { class: "offer-details-modal__close-text" }, "Schließen", -1 /* HOISTED */)),
        _createVNode(BaseIcon, {
          name: "close",
          class: "offer-details-modal__close-icon"
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default"),
      (!isDesktop.value)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            type: "button",
            class: "offer-details-modal__close-button--flights-tablet",
            onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (closeModal()), ["prevent"]))
          }, [
            _createVNode(BaseIcon, {
              name: "chevron",
              class: "offer-details-modal__close-button-icon"
            }),
            _cache[6] || (_cache[6] = _createElementVNode("span", { class: "offer-details-modal__close-button-label" }, "zurück", -1 /* HOISTED */))
          ]))
        : _createCommentVNode("v-if", true)
    ]),
    _createElementVNode("button", {
      type: "button",
      class: "offer-details-modal__close-button",
      onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (closeModal()), ["prevent"]))
    }, [
      _createVNode(BaseIcon, {
        name: "chevron",
        class: "offer-details-modal__close-button-icon"
      }),
      _cache[7] || (_cache[7] = _createElementVNode("span", { class: "offer-details-modal__close-button-label" }, "zurück", -1 /* HOISTED */))
    ])
  ], 2 /* CLASS */))
}
}

})