<template>
	<div class="price-filter">
		<div class="price-filter__header">
			<h2 class="price-filter__title">
				Reisepreis <span class="price-filter__inline-text">pro Person</span>
			</h2>
			<p
				class="price-filter__value"
			>
				{{ priceValue }}
			</p>
		</div>
		<div>
			<range-slider
				v-model="price"
				:max="maxPrice"
				class="price-filter__slider"
				@RangeSlider:Select="onSelect"
				@RangeSlider:DragStart="onDragStart"
				@RangeSlider:DragEnd="onDragEnd"
			/>
		</div>
	</div>
</template>

<script lang="ts" setup>
import RangeSlider from '@lmt-rpb/RangeSlider/RangeSlider.vue';
import { computed, ref, watch } from 'vue';
import { useStore } from '@/components/common/store';
import { Currency, formatPrice } from '@/js/utils/priceUtils';

interface Props {
	maxPrice?: number,
	defaultValue?: number,
	currency?: Currency,
	step?: number,
}

const props = withDefaults(defineProps<Props>(), {
	maxPrice: 1600,
	defaultValue: -1,
	currency: 'EUR',
	step: 100,
});

const store = useStore();

const price = ref(0);

const dragging = ref(false);

price.value = props.defaultValue !== -1 ? props.defaultValue : props.maxPrice;

watch(() => props.defaultValue, (value) => {
	price.value = value;
});

const roundPrice = (amount: number) => {
	if (amount <= props.step) {
		return props.step;
	}
	return Math.round(amount / props.step) * props.step;
};

const priceValue = computed((): string => {
	if (price.value > (props.maxPrice - props.step)) {
		return 'Beliebig';
	}
	if (price.value < props.step) {
		return `bis ${formatPrice(props.step, props.currency)}`;
	}
	return ` bis ${formatPrice(roundPrice(price.value), props.currency)}`;
});

const onSelect = (count: number) => {
	store.commit('searchMask/updateFormData', { maxPrice: roundPrice(count) });
};

const onDragStart = () => {
	dragging.value = true;
};

const onDragEnd = () => {
	dragging.value = false;
	onSelect(price.value);
};
</script>

<style lang="scss" scoped>
.price-filter {
	:deep(.range .range__track) {
		--range-slider-track-size: 0.8rem;
	}


	padding-right: 1.2rem;

	:deep(.range .range__handler) {
		width: 2.4rem;
		height: 2.4rem;
		margin-top: calc(2.4rem / -2);
		border-width: 0.2rem;
	}

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		column-gap: 0.8rem;
		cursor: default;
		margin-bottom: 2.4rem;
	}

	&__title {
		padding: 0;
		text-align: left;
		font-family: $font-family;
		color: $color-filter-type-header-text;
		font-size: $font-medium-3;
		font-weight: $font-weight-semibold;
		margin-bottom: 0;
	}

	&__inline-text {
		font-size: $font-small-2;
		font-weight: $font-weight-semibold;
		font-family: $font-family;
		color: $color-filter-type-header-text;
	}

	&__value {
		color: $color-primary;
		font-size: $font-small-1;
		font-weight: $font-weight-semibold;
		text-align: left;
		margin: 0;
	}
}

@media (min-width: $breakpoint-extralarge) {
	.price-filter {
		padding-bottom: 0;

		&__header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			background-color: initial;
			box-shadow: none;
			margin-bottom: 1.6rem;
			padding: 0 0 0.8rem;
		}

		&__slider {
			margin-left: 0.6rem;
		}

		&__value {
			display: block;
			margin-bottom: 0;
		}
	}
}
</style>
