import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

import { ref, computed } from 'vue';
import FilterType from '@lmt-rpb/FilterType/FilterType.vue';
import ShowMoreContainer from '@lmt-rpb/ShowMoreContainer/ShowMoreContainer.vue';
import Checkbox from '@lmt-rpb/CheckBox/CheckBox.vue';
import { useStore } from '@/components/common/store';
import { mostPopularTypes } from '@/js/data/index';


export default /*@__PURE__*/_defineComponent({
  __name: 'MostPopularFiltersNew',
  setup(__props) {

const store = useStore();
const listOfAvailableFilters = ref(mostPopularTypes);

const showAllFilters = ref(false);

const visibleFilters = computed(() => (showAllFilters.value ? listOfAvailableFilters.value : listOfAvailableFilters.value.slice(0, 4)));

const hiddenFilters = computed(() => (showAllFilters.value ? [] : listOfAvailableFilters.value.slice(4)));

const hotelFilters = computed({
	get() {
		return store.state.searchMask.mostPopularFilters?.hotelAttributes || [];
	},
	set(value: string[]) {
		store.commit('searchMask/updateFormData', {
			mostPopularFilters: { ratingAttributes: ratingFilters.value, hotelAttributes: value }
		});
	}
});

const ratingFilters = computed({
	get() {
		return store.state.searchMask.mostPopularFilters?.ratingAttributes || [];
	},
	set(value: string[]) {
		store.commit('searchMask/updateFormData', {
			mostPopularFilters: { ratingAttributes: value, hotelAttributes: hotelFilters.value }
		});
	}
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(FilterType, {
    icon: "",
    title: "Beliebteste Filter",
    class: "most-popular-filter"
  }, {
    default: _withCtx(() => [
      _createVNode(ShowMoreContainer, {
        "item-count": listOfAvailableFilters.value.length
      }, {
        visibleContent: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(visibleFilters.value, (availableFilter, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: index,
              class: "most-popular-filter__list-item"
            }, [
              (availableFilter.attrType === 'HotelAttributes')
                ? (_openBlock(), _createBlock(Checkbox, {
                    key: 0,
                    modelValue: hotelFilters.value,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((hotelFilters).value = $event)),
                    label: availableFilter.label,
                    "custom-value": availableFilter.value
                  }, null, 8 /* PROPS */, ["modelValue", "label", "custom-value"]))
                : (_openBlock(), _createBlock(Checkbox, {
                    key: 1,
                    modelValue: ratingFilters.value,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((ratingFilters).value = $event)),
                    label: availableFilter.label,
                    "custom-value": availableFilter.value
                  }, null, 8 /* PROPS */, ["modelValue", "label", "custom-value"]))
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ]),
        hiddenContent: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(hiddenFilters.value, (availableFilter, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: index,
              class: "most-popular-filter__list-item"
            }, [
              (availableFilter.attrType === 'HotelAttributes')
                ? (_openBlock(), _createBlock(Checkbox, {
                    key: 0,
                    modelValue: hotelFilters.value,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((hotelFilters).value = $event)),
                    label: availableFilter.label,
                    "custom-value": availableFilter.value
                  }, null, 8 /* PROPS */, ["modelValue", "label", "custom-value"]))
                : (_openBlock(), _createBlock(Checkbox, {
                    key: 1,
                    modelValue: ratingFilters.value,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((ratingFilters).value = $event)),
                    label: availableFilter.label,
                    "custom-value": availableFilter.value
                  }, null, 8 /* PROPS */, ["modelValue", "label", "custom-value"]))
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["item-count"])
    ]),
    _: 1 /* STABLE */
  }))
}
}

})