import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "filter-container__header"
}

type Props = {
	title?: string
	withDivider?: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FilterContainer',
  props: {
    title: { default: undefined },
    withDivider: { type: Boolean, default: true }
  },
  setup(__props: any) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["filter-container", { 'filter-container--with-divider': _ctx.withDivider }])
  }, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("h3", _hoisted_1, _toDisplayString(_ctx.title), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    _renderSlot(_ctx.$slots, "default")
  ], 2 /* CLASS */))
}
}

})