<template>
	<menu class="show-more-container__list">
		<slot name="visibleContent"></slot>
		<TransitionExpand
			:show-content="isMobile || showMore"
		>
			<slot name="hiddenContent"></slot>
		</TransitionExpand>
	</menu>
	<button
		v-if="!isMobile"
		class="show-more-container__show-more"
		@click="handleShowMore"
	>
		{{ buttonText }}
	</button>
</template>
<script setup lang="ts">
import TransitionExpand from '@lmt-rpb/TransitionExpand/TransitionExpand.vue';
import { useStore } from '@/components/common/store';
import { ref, computed } from 'vue';

type Props = {
	itemCount: number
}

const props = defineProps<Props>();

const store = useStore();
const showMore = ref(false);
const isMobile = computed(() => !store.state.config.isDesktop);
const handleShowMore = () => {
	showMore.value = !showMore.value;
};
const buttonText = computed(() => (showMore.value ? 'Weniger anzeigen' : `Alle ${props.itemCount} anzeigen`));
</script>

<style lang="scss" scoped>
$content-left-margin: 0.8rem;

.show-more-container__list {
	margin: 0;
	list-style: none;
	padding: 0;
	margin-left: $content-left-margin;
}

.show-more-container__show-more {
	box-shadow: none;
	border: none;
	background: none;
	appearance: none;
	color: $color-show-more;
	font-weight: $font-weight-semibold;
	margin: 0;
	margin-top: 0.6rem;
	margin-bottom: 0;
	margin-left: $content-left-margin;
}
</style>
