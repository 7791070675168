import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

import FilterType from '@lmt-rpb/FilterType/FilterType.vue';
import ShowMoreContainer from '@lmt-rpb/ShowMoreContainer/ShowMoreContainer.vue';
import Checkbox from '@lmt-rpb/CheckBox/CheckBox.vue';
import { computed, ref } from 'vue';
import { additionalCheckboxFilterTypes } from '@/js/data/index';
import { useStore } from '@/components/common/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomerRatingFilter',
  setup(__props) {

const store = useStore();

const listOfAvailableFilters = ref(additionalCheckboxFilterTypes);
const visibleFilters = listOfAvailableFilters.value.slice(0, 5);
const hiddenFilters = listOfAvailableFilters.value.slice(5);

const ratingFilters = computed({
	get() {
		return store.state.searchMask.additionalCheckboxFilters?.ratingAttributes as string[];
	},
	set(value: string[]) {
		store.commit(
			'searchMask/updateFormData',
			{ additionalCheckboxFilters: { ratingAttributes: value } }
		);
	}
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(FilterType, {
    title: "Kundenbewertungen",
    class: "customer-rating-filter"
  }, {
    default: _withCtx(() => [
      _createVNode(ShowMoreContainer, {
        "item-count": listOfAvailableFilters.value.length
      }, {
        visibleContent: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(visibleFilters), (availableFilter, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: index,
              class: "customer-rating-filter__list-item"
            }, [
              _createVNode(Checkbox, {
                modelValue: ratingFilters.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((ratingFilters).value = $event)),
                label: availableFilter.label,
                "custom-value": availableFilter.value
              }, null, 8 /* PROPS */, ["modelValue", "label", "custom-value"])
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ]),
        hiddenContent: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(hiddenFilters), (availableFilter, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: index,
              class: "customer-rating-filter__list-item"
            }, [
              _createVNode(Checkbox, {
                modelValue: ratingFilters.value,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((ratingFilters).value = $event)),
                label: availableFilter.label,
                "custom-value": availableFilter.value
              }, null, 8 /* PROPS */, ["modelValue", "label", "custom-value"])
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["item-count"])
    ]),
    _: 1 /* STABLE */
  }))
}
}

})