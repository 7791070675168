<template>
	<div class="checkout-infos">
		<img
			:src="mediumImage"
			class="checkout-infos__image"
			:alt="hotelName"
		>
		<div class="checkout-infos__operator-hotel-wrapper">
			<section class="checkout-infos__hotel">
				<h1 class="checkout-infos__hotel-name">
					{{ hotelName }}
					<Rating
						class="hotel-name__rating"
						:rate="hotelCategory"
						:suns="showSuns"
					/>
				</h1>
				<span
					v-if="hotelAddress"
					class="checkout-infos__hotel-address"
				>
					{{ hotelAddress }}
				</span>
			</section>
			<div class="checkout-infos__operator">
				<img
					class="checkout-infos__operator-image"
					:src="tourOperator.Logo"
					:alt="tourOperator.Name + ' Logo'"
				>
				<div class="checkout-infos__operator">
					<transition name="info-text">
						<div
							v-if="showInfoText"
							class="checkout-infos__info-bubble"
						>
							<ul class="checkout-infos__info-text">
								<li>{{ tourOperator.LegalEntity.Name }}</li>
								<li>{{ tourOperator.LegalEntity.Address }}</li>
								<li>{{ tourOperator.LegalEntity.CountryCode }} - {{ tourOperator.LegalEntity.PostalCode }} {{ tourOperator.LegalEntity.City }}</li>
								<li
									v-for="item in tourOperator.LegalEntity.Contacts"
									:key="item"
								>
									{{ item }}
								</li>
							</ul>
						</div>
					</transition>
					Veranstalter: {{ tourOperator.Name }} <BaseIcon
						name="infoFill"
						class="checkout-infos__info"
						@keypress.enter="showInfoText = !showInfoText"
						@mouseover="showInfoText = true"
						@mouseleave="showInfoText = false"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import { ref, computed } from 'vue';
import Rating from '@/components/common/widgets/rating.vue';
import { TourOperatorComplete } from '@/interfaces/checkout/shopping-cart';
import { getFluxImageUrl } from '@/js/utils/utils';
import { isClientGermany } from '@/js/utils/environmentUtils';

interface Props {
	iffCode: number,
	hotelName: string,
	hotelCategory: number,
	tourOperator: TourOperatorComplete,
	hotelAddress?: string,
	client?: string,
}

const props = withDefaults(defineProps<Props>(), {
	hotelAddress: '',
	client: 'at',
});

const showInfoText = ref(false);

const imageUrl = computed((): string => {
	let url = getFluxImageUrl();

	if (!url) {
		url = 'https://images.flux.reisen';
	}

	return url;
});

const mediumImage = computed((): string => `${imageUrl.value}/hotel/${props.iffCode}/1/m`);
const showSuns = isClientGermany();

</script>

<style lang="scss" scoped>
:deep(.info-tooltip__icon) {
	margin-left: 0.5rem;
	height: 1.7rem;
	width: 1.7rem;
	vertical-align: middle;
}

:deep(.info-tooltip__icon-container) {
	top: 0 !important;
}

.checkout-infos {
	margin: 0 2.4rem 1.6rem;
	padding: 0 0 1.6rem;
	border-bottom: 0.1rem solid $color-primary-l4;
	display: flex;
	flex-direction: column;
	gap: 1.6rem;

	&__operator-hotel-wrapper {
		font-size: $font-small-1;
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: 1.6rem;
	}

	&__operator {
		position: relative;
		white-space: nowrap;
		width: fit-content;
	}

	&__operator-image {
		height: 2.5rem;
		margin-bottom: 1rem;
	}

	&__info-bubble {
		width: fit-content;
		position: absolute;
		display: inline-block;
		top: 2.5rem;
		right: -0.75rem;
		font-size: $font-small-3;
		background: $color-primary;
		color: $color-white;
		border-radius: $tool-tip-border-radius;

		ul {
			padding: 1rem;
			margin-bottom: 0;
			white-space: normal;
		}

		li {
			list-style-type: none;
			margin-left: 0;
		}
	}

	&__info-bubble::before {
		content: '';
		top: -1rem;
		right: 1rem;
		position: absolute;
		border: 0.5rem solid transparent;
		border-bottom-color: $color-primary;
	}

	@media screen and (min-width: $breakpoint-verysmall) {
		margin: 0 4rem 1.6rem;
	}

	@media screen and (min-width: $breakpoint-small) {
		padding-bottom: 1.6rem;
	}

	&__image {
		margin-left: calc((2.4rem) * -1);
		width: calc(100% + 2 * 2.4rem);
		border-radius: $border-image-radius-checkout-thank-you;
		min-height: 20.8rem;
		object-fit: cover;

		@media screen and (min-width: $breakpoint-verysmall) {
			margin-left: calc((4rem) * -1);
			width: calc(100% + 2 * 4rem);
		}
	}

	@media (min-width: $breakpoint-small) {
		padding-top: 2.4rem;
		margin: 0 4rem 1.6rem;
		flex-direction: row-reverse;

		&__image {
			margin-left: unset;
			flex: 1;
			width: 50%;
			border-radius: $border-image-radius-checkout-thank-you;
		}
	}

	&__hotel-name {
		font-size: $font-medium-3;
		color: $color-text;
		text-align: left;
		margin-bottom: 0.3rem;
		padding: 0;

		:deep(.rating) {
			white-space: nowrap;
		}
	}

	&__info {
		position: relative;
		top: 0.1rem;
		fill: $color-primary;
		width: 1.4rem;
	}

	&__hotel-address {
		font-size: $font-small-2;
		font-weight: $font-weight-regular;
	}

}
</style>
