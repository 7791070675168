import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from 'vue';
import FilterType from '@lmt-rpb/FilterType/FilterType.vue';
import FilterRadioButtonList from '@lmt-rpb/FilterRadioButtonList/FilterRadioButtonList.vue';
import recommendationRate from '@/js/data/recommendation-rate-types';
import { useStore } from '@/components/common/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'RecommendationRateFilter',
  setup(__props) {

const store = useStore();

const selectedRating = computed({
	get: () => store.state.searchMask.minMeanRecommendationRate?.toString(),
	set: (newValue: string) => store.commit('searchMask/updateFormData', { minMeanRecommendationRate: Number.parseInt(newValue) }),
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(FilterType, {
    icon: "",
    title: "Weiterempfehlungsrate",
    class: "recommendation-rate-filter"
  }, {
    default: _withCtx(() => [
      _createVNode(FilterRadioButtonList, {
        id: "recommendation-rate",
        modelValue: selectedRating.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedRating).value = $event)),
        items: _unref(recommendationRate),
        class: "recommendation-rate-filter__list"
      }, null, 8 /* PROPS */, ["modelValue", "items"])
    ]),
    _: 1 /* STABLE */
  }))
}
}

})