import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, withCtx as _withCtx, Transition as _Transition, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal modal-mask" }
const _hoisted_2 = { class: "modal-container" }
const _hoisted_3 = {
  key: 0,
  class: "modal-title"
}

import RoundedButton from '@lmt-rpb/RoundedButton/RoundedButton.vue';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';

interface Props {
	header?: string,
	title?: string,
	buttonText?: string,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'SmallModal',
  props: {
    header: {},
    title: {},
    buttonText: {}
  },
  emits: ['SmallModal:Close'],
  setup(__props: any, { emit: __emit }) {



const emit = __emit;

const onCloseClick = () => emit('SmallModal:Close');


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Transition, { name: "modal" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: _normalizeClass([{'has-title': _ctx.title}, "modal-top"])
          }, [
            (_ctx.title)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.title), 1 /* TEXT */))
              : _createCommentVNode("v-if", true),
            _createElementVNode("button", {
              type: "button",
              class: "modal-close",
              onClick: onCloseClick
            }, [
              _createVNode(BaseIcon, {
                name: "cancel",
                class: _normalizeClass(["modal-close__icon", {'has-title': _ctx.title}])
              }, null, 8 /* PROPS */, ["class"])
            ])
          ], 2 /* CLASS */),
          _createElementVNode("h2", {
            class: _normalizeClass(["modal-header", {'has-title': _ctx.title}])
          }, _toDisplayString(_ctx.header), 3 /* TEXT, CLASS */),
          _createElementVNode("div", {
            class: _normalizeClass(["modal-body", {'has-title': _ctx.title}])
          }, [
            _createCommentVNode(" @slot the main text of the body "),
            _renderSlot(_ctx.$slots, "body")
          ], 2 /* CLASS */),
          (_ctx.buttonText)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["modal-footer", {'has-title': _ctx.title}])
              }, [
                _createVNode(RoundedButton, {
                  class: "modal-footer__button modal-footer__button-left",
                  theme: "extra",
                  onClick: onCloseClick
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.buttonText), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                })
              ], 2 /* CLASS */))
            : _createCommentVNode("v-if", true)
        ])
      ])
    ]),
    _: 3 /* FORWARDED */
  }))
}
}

})