import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from 'vue';
import FilterType from '@lmt-rpb/FilterType/FilterType.vue';
import FilterRadioButtonList from '@lmt-rpb/FilterRadioButtonList/FilterRadioButtonList.vue';
import { hotelCategory, hotelCategoryCom } from '@/js/data/hotel-category-types';
import { useStore } from '@/components/common/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'HotelCategoryFilter',
  props: {
	showSuns: {
		type: Boolean,
		default: undefined,
	}
},
  setup(__props) {

const props = __props;

// if showSuns is provided it should get hotelCategoryCom
const hotelCategoryOptions = computed(() => (props.showSuns ? hotelCategoryCom : hotelCategory));

const store = useStore();

const selectedCategory = computed({
	get: () => store.state.searchMask.hotelCategory?.toString(),
	set: (newValue) => store.commit('searchMask/updateFormData', { hotelCategory: newValue }),
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(FilterType, {
    icon: "",
    title: "Hotelkategorie",
    class: "hotel-category-filter"
  }, {
    default: _withCtx(() => [
      _createVNode(FilterRadioButtonList, {
        id: "hotel-category",
        modelValue: selectedCategory.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedCategory).value = $event)),
        items: hotelCategoryOptions.value,
        class: "hotel-category-filter__list"
      }, null, 8 /* PROPS */, ["modelValue", "items"])
    ]),
    _: 1 /* STABLE */
  }))
}
}

})