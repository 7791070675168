<template>
	<div :class="['info-box-status', infoBoxClasses]">
		<div
			:class="['info-box-status__bar', infoBarClasses]"
			@click.stop="onBarClick"
		>
			<span
				class="info-box-status__title"
				v-text="getStatusDetails.title"
			></span>
			<BaseIcon
				v-if="getStatusDetails.icon"
				:name="getStatusDetails.icon"
				class="info-box-status__icon"
			/>
		</div>
		<slot></slot>
	</div>
</template>
<script setup lang="ts">
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import { computed, toRef, useSlots } from 'vue';
import type { OfferStatusCheck } from '@/interfaces/offer';
import { useOfferStatus } from '../../composables/offerStatus';

type Props = {
	status?: OfferStatusCheck,
	barClasses?: string,
	absoluteBar?: boolean,
	interactionOnRequest?: boolean
}

const props = withDefaults(defineProps<Props>(), {
	interactionOnRequest: false
});

const emit = defineEmits(['on-request-action']);

const slots = useSlots();

const {
	isOfferAvailable,
	isOfferOnRequest,
	offerCanBeChecked
} = useOfferStatus(toRef(props, 'status'));

const getStatusDetails = computed(() => {
	let title = 'Angebot nicht auf Verfügbarkeit/Preis prüfbar';
	let icon;

	if (isOfferAvailable.value) {
		title = 'Angebot verfügbar';
		icon = 'check';
	} else if (isOfferOnRequest.value) {
		title = 'Angebot auf Anfrage buchbar';
		icon = props.interactionOnRequest ? 'infoFill' : undefined;
	}

	return { title, icon };
});

const onBarClick = () => {
	if (props.interactionOnRequest && isOfferOnRequest.value) {
		emit('on-request-action');
	}
};

const infoBoxClasses = computed(() => ({
	'info-box-status--check-unable': offerCanBeChecked.value,
	'info-box-status--wrapper': Boolean(slots?.default),
	'info-box-status--on-request': isOfferOnRequest.value
}));

const infoBarClasses = computed(() => ({
	[`${props.barClasses}`]: props.barClasses,
	'info-box-status__bar--absolute': props.absoluteBar,
	'info-box-status__bar--interactive': props.interactionOnRequest
}));
</script>

<style lang="scss" scoped>
.info-box-status {
	position: relative;

	&__bar {
		top: 0;
		width: 100%;
		background-color: $color-lime-light;
		border-radius: 0 0 1.5rem 1.5rem;
		padding: 0.329rem 1.856rem 0.571rem;
		text-align: center;
		font-size: $font-small-1;
		font-weight: $font-weight-semibold;
		display: flex;
		align-items: center;
		justify-content: center;
		column-gap: 0.5rem;

		&--absolute {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			width: calc(100% - 3.2rem);
		}

		&--interactive {
			cursor: pointer;
		}

		&:not(&.info-box-status__bar--absolute) {
			@include media-query-up($breakpoint-medium) {
				justify-content: space-between;
			}
		}
	}

	&__icon {
		fill: $offer-verified-checkmark-color;
		width: 1.7rem;
		height: 1.7rem;
	}

	&--wrapper {
		border: 0.2rem solid $color-lime-light;
		border-radius: $border-radius-medium;
	}

	&--check-unable {
		border-color: $color-primary-l4;

		.info-box-status__bar {
			background-color: $color-primary-l4;
		}
	}

	&--on-request {
		border-color: $color-warning;

		.info-box-status__icon {
			fill: $color-white;
		}

		.info-box-status__bar {
			background-color: $color-warning;
			color: $color-white;
		}
	}
}
</style>
