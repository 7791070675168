import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from 'vue';
import FilterType from '@lmt-rpb/FilterType/FilterType.vue';
import FilterRadioButtonList from '@lmt-rpb/FilterRadioButtonList/FilterRadioButtonList.vue';
import { ANY } from '@global-js/constants';
import { transferTypesNew as transferTypes } from '@/js/data/transfer-types';
import { useStore } from '@/components/common/store';


export default /*@__PURE__*/_defineComponent({
  __name: 'HotelTransferFilter',
  setup(__props) {

const store = useStore();

const selectedTransfer = computed({
	get: () => {
		const transferType = store.state.searchMask.transferTypes;
		return transferType.length === 0 ? ANY : transferType[0];
	},
	set: (newValue) => {
		const valueToCommit = newValue === ANY ? [] : [newValue];
		store.commit('searchMask/updateFormData', { transferTypes: valueToCommit });
	},
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(FilterType, {
    icon: "transfer",
    title: "Hoteltransfer",
    class: "hotel-transfer-filter"
  }, {
    default: _withCtx(() => [
      _createVNode(FilterRadioButtonList, {
        id: "hotel-transfer",
        modelValue: selectedTransfer.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedTransfer).value = $event)),
        items: _unref(transferTypes),
        class: "hotel-transfer-filter__list"
      }, null, 8 /* PROPS */, ["modelValue", "items"])
    ]),
    _: 1 /* STABLE */
  }))
}
}

})